import { Component, Fragment, ReactNode } from "react";

interface TimerProps {
    blockTime: number;
    lastBlockTimestamp: number;
}

interface TimerState {
    blockTime: number;
    timer: NodeJS.Timer|null;
}

export class BlockTimer extends Component<TimerProps, TimerState> {
    state: TimerState = {
        blockTime: 0,
        timer: null,
    };

    public componentDidMount(): void {
        const nextBlockTimestamp = this.props.lastBlockTimestamp + this.props.blockTime;
        this.setState({
            blockTime: Math.max(nextBlockTimestamp - Math.ceil(Date.now() / 1000), 0),
            timer: setInterval(() => {
                this.setState({ blockTime: Math.max(nextBlockTimestamp - Math.ceil(Date.now() / 1000), 0) });
            }, 1000)
        });
    }

    public componentWillUnmount(): void {
        if (this.state.timer !== null) {
            clearInterval(this.state.timer);
        }
    }

    public render(): ReactNode {
        return (<Fragment>{this.state.blockTime}</Fragment> );
    }
}
