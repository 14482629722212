import { validateInt, validateString } from "../components/util/helpers";

export const lisk = {
    NETWORK_URL: validateString(process.env.REACT_APP_LISK_NETWORK_URL),
    CHAIN_ID: validateInt(process.env.REACT_APP_LISK_CHAIN_ID),
    CHAIN_NAME: validateString(process.env.REACT_APP_LISK_CHAIN_NAME),
    CURRENCY_NAME: validateString(process.env.REACT_APP_LISK_CURRENCY_NAME),
    CURRENCY_SYMBOL: validateString(process.env.REACT_APP_LISK_CURRENCY_SYMBOL),
    PROJECT_ID: validateString(process.env.REACT_APP_LISK_PROJECT_ID),
};
