import assert from "assert";
import Konva from "konva";
import { IFrame } from "konva/lib/types";
import { v4 as uuid } from "uuid";
import { ApiClient } from "../clients/api_client";
import { decodePixels, deserialiseColours, deserialiseCoords } from "../clients/codec";
import { TimelapseSummary } from "../clients/types";
import { debounce } from "../util/helpers";
import { Canvas } from "./canvas";
import { DeferredRender } from "./deferred_render";
import viewDecoder, { DecodeResponse } from "./view_decoder";
import { ChunkView, SeekBarProperties } from "./types";
import gearIcon from "../../assets/images/timelapse-gear-icon.svg";
import gearIconHover from "../../assets/images/timelapse-gear-icon-hover.svg";
import replayIcon from "../../assets/images/timelapse-replay-icon.svg";
import replayIconHover from "../../assets/images/timelapse-replay-icon-hover.svg";

export class MediaControls implements DeferredRender {
    private readonly canvas: Canvas;
    private readonly layer: Konva.Layer;
    private readonly stage: Konva.Stage;
    private readonly group: Konva.Group;
    private readonly summary: TimelapseSummary;
    private decoder: Worker|null = null;
    private decoded: Record<string, Uint8ClampedArray> = {};
    private showAnim: Konva.Animation|null = null;
    private hideAnim: Konva.Animation|null = null;
    private hideOnIdle: () => void = debounce(() => this.hideStart(), 3000);
    private seekProperties: SeekBarProperties|null = null;
    private seekAnimation: Konva.Animation|null = null;
    private playing: boolean = false;
    private chunks: Record<number, ChunkView> = {};
    private downloading: number[] = [];
    private failedDownloads: number = 0;
    private lastApplied: { chunk: number, blockHeight: number }|null = null;

    public constructor(summary: TimelapseSummary, canvas: Canvas, layer: Konva.Layer, stage: Konva.Stage) {
        this.summary = summary;
        this.canvas = canvas;
        this.layer = layer;
        this.stage = stage;
        this.group = new Konva.Group();
    }

    public async load(): Promise<void> {
        const worker = new Blob([`(${viewDecoder})()`]);
        this.decoder = new Worker(URL.createObjectURL(worker));
        this.decoder.onmessage = (e: MessageEvent<DecodeResponse>) => {
            const { id, view } = e.data;
            this.decoded[id] = view;
        };

        const iconSize = 24;
        const margin = iconSize * (5 / 7);

        const playWidth = iconSize * (5 / 7);
        const playHeight = iconSize;

        const settingsWidth = iconSize;
        const settingsHeight = iconSize;

        const seekWidth = this.stage.width() - (margin + playWidth + (margin * 1.5)) - ((margin * 1.5) + settingsWidth + margin);
        const seekHeight = iconSize * 0.75;

        // Black transparent background with controls over the top
        const background = new Konva.Rect({
            x: 0,
            y: this.stage.height() - (iconSize * 2),
            width: this.stage.width(),
            height: iconSize * 2,
            fill: "#1F1F1F",
            opacity: 0.7,
        });

        const play = await this.createPlayGroup(playWidth, playHeight);
        play.x(margin);
        play.y(this.stage.height() - (playHeight * 2 * 0.75));

        const seek = this.createSeekGroup(seekWidth, seekHeight);
        seek.x(margin + playWidth + (margin * 1.5));
        seek.y(this.stage.height() - (seekHeight * 2 * 0.75));

        const settings = await this.createSettingsGroup(settingsWidth, settingsHeight);
        settings.x(margin + playWidth + (margin * 1.5) + seekWidth + (margin * 1.5));
        settings.y(this.stage.height() - (settingsHeight * 2 * 0.75));

        this.group.add(background);
        this.group.add(play);
        this.group.add(seek);
        this.group.add(settings);
        this.group.on("mousemove mousedown click dragmove wheel touchstart touchmove", () => this.canvas.disableDrag());
        this.group.on("mouseout mouseleave touchend", () => this.canvas.enableDrag());

        this.layer.add(this.group);
        this.stage.on("mousemove mousedown click dragmove wheel touchstart touchmove tap", () => this.showStart());
        this.stage.on("mouseout mouseleave", () => this.hideStart());

        this.showStart();
        this.play();
    }

    public unload(): void {
        this.group.remove();
        this.group.removeChildren();
        this.showStop();
        this.hideStop();
        this.seekAnimation?.stop();
        this.seekAnimation = null;
        this.decoder?.terminate();
        this.decoder = null;
        this.stage.off("mousemove mousedown click dragmove wheel touchstart touchmove tap mouseout mouseleave");
    }

    public fitIntoStage(stage: Konva.Stage): void {
        // TODO: implement
        this.seekProperties = this.generateSeekProperties();
    }

    private getSeekProperties(): SeekBarProperties {
        if (this.seekProperties === null) {
            this.seekProperties = this.generateSeekProperties();
        }

        return this.seekProperties;
    }

    private showStart(): void {
        if (this.showAnim !== null) {
            return;
        }

        this.hideStop();
        this.hideOnIdle();

        this.showAnim = new Konva.Animation((frame?: IFrame) => {
            if (!frame) {
                return;
            }

            if (this.group.opacity() === 1) {
                this.showStop();
                return;
            }

            // Bail if failed to show in 10 seconds
            if (frame.time > 10000) {
                this.showStop();
                return;
            }

            this.group.opacity(Math.min(this.group.opacity() + Math.sqrt(frame.timeDiff / 2000), 1));
        }, this.layer).start();
    }

    private showStop(): void {
        this.showAnim?.stop();
        this.showAnim = null;
    }

    private hideStart(): void {
        if (this.hideAnim !== null) {
            return;
        }

        this.showStop();

        this.hideAnim = new Konva.Animation((frame?: IFrame) => {
            if (!frame) {
                return;
            }

            if (this.group.opacity() === 0) {
                this.hideStop();
                return;
            }

            // Bail if failed to show in 10 seconds
            if (frame.time > 10000) {
                this.hideStop();
                return;
            }

            this.group.opacity(Math.max(this.group.opacity() - Math.sqrt(frame.timeDiff / 2000), 0));
        }, this.layer).start();
    }

    private hideStop(): void {
        this.hideAnim?.stop();
        this.hideAnim = null;
    }

    private play(): void {
        if (this.seekAnimation === null) {
            this.seekAnimation = new Konva.Animation((frame?: IFrame) => {
                if (!frame) {
                    return;
                }

                const { seekCursor, cursorMax, slidePercentage } = this.getSeekProperties();
                const slide = Math.min(seekCursor.x() + (frame.timeDiff * slidePercentage), cursorMax);

                if (!this.renderFrame(slide)) {
                    return;
                }

                seekCursor.x(slide);

                if (slide === cursorMax) {
                    this.complete();
                }

                this.stage.batchDraw();
            }, this.layer);
        }

        this.seekAnimation.start();
        this.playing = true;
    }

    private pause(): void {
        this.seekAnimation?.stop();
        this.playing = false;
    }

    private replay(): void {
        this.seekProperties?.seekCursor.x(this.seekProperties?.cursorMin);
        this.play();
    }

    private complete(): void {
        this.seekAnimation?.stop();
        const playGroup = this.group.findOne<Konva.Group>("#playGroup");
        const pause = playGroup.findOne<Konva.Image>("#pause");
        pause.visible(false);
        const replay = playGroup.findOne<Konva.Image>("#replay");
        replay.visible(true);
        this.playing = false;
    }

    private renderFrame(cursorPos: number): boolean {
        const { cursorMin, barLength, totalBlocks } = this.getSeekProperties();
        const blockHeight = Math.floor(((cursorPos - cursorMin) / barLength) * totalBlocks) + this.summary.startBlockHeight;
        const chunkIndex = blockHeight - ((blockHeight - this.summary.startBlockHeight) % this.summary.chunkSize);
        const chunks = this.calculateNextChunks(chunkIndex, 3);

        this.download(...chunks);

        if (!(chunkIndex in this.chunks)) {
            return false;
        }

        const chunk = this.chunks[chunkIndex];

        if (chunkIndex !== this.lastApplied?.chunk) {
            this.canvas.copyCanvas(chunk.snapshot);
        }

        if (blockHeight !== this.lastApplied?.blockHeight) {
            const xCoords: number[] = [];
            const yCoords: number[] = [];
            const colours: string[] = [];

            for (let i = this.lastApplied?.blockHeight ?? this.summary.startBlockHeight; i <= blockHeight; i++) {
                if (i in this.chunks[chunkIndex].blocks) {
                    for (const transaction of chunk.blocks[i]) {
                        const pixels = decodePixels(
                            deserialiseCoords(transaction[0]),
                            deserialiseColours(transaction[1]),
                            this.summary
                        );
                        xCoords.push(...pixels.xCoords);
                        yCoords.push(...pixels.yCoords);
                        colours.push(...pixels.colours);
                    }
                }
            }

            this.canvas.updateCanvas(xCoords, yCoords, colours);
        }

        this.lastApplied = { chunk: chunkIndex, blockHeight };
        return true;
    }

    private download(...chunks: number[]): void {
        for (const chunk of chunks) {
            if (!(chunk in this.chunks) && !this.downloading.includes(chunk)) {
                ApiClient.getInstance().queryTimelapseChunk(this.summary.canvasId, chunk)
                    .then((result) => {
                        if (result === null) {
                            // Bailing if failed to download 10 times
                            if (++this.failedDownloads >= 10) {
                                throw new Error("Failed to download chunk");
                            }

                            // Retrying on failure
                            this.download(chunk);
                            return;
                        }

                        this.createSnapshotView(result.snapshot).then((view) => {
                            this.chunks[chunk] = { ...result, snapshot: view };

                            const downloadIndex = this.downloading.findIndex((value) => value === chunk);

                            if (downloadIndex >= 0) {
                                delete this.downloading[downloadIndex];
                            }
                        });
                    })
                    .catch(() => this.download(chunk));
                this.downloading.push(chunk);
            }
        }
    }

    private async createSnapshotView(snapshot: string): Promise<HTMLCanvasElement> {
        const data = await this.decodeView(snapshot);
        const canvas = document.createElement("canvas");
        canvas.width = this.summary.width;
        canvas.height = this.summary.height;
        const ctx = canvas.getContext("2d", { willReadFrequently: true });
        assert(ctx !== null, "Failed to retrieve 2d context");
        ctx.putImageData(new ImageData(data, this.summary.width, this.summary.height, { colorSpace: "srgb" }), 0, 0);
        return canvas;
    }

    private async decodeView(view: string): Promise<Uint8ClampedArray> {
        if (!window.Worker || this.decoder === null) {
            throw new Error("Decoder not started");
        }

        const id = uuid();
        this.decoder.postMessage({
            id,
            view,
            properties: this.summary,
        });

        return new Promise(resolve => {
            const interval = setInterval(() => {
                if (id in this.decoded) {
                    clearInterval(interval);
                    const decoded = this.decoded[id];
                    delete this.decoded[id];
                    resolve(decoded);
                }
            }, 100)
        });
    }

    private onMouseMove(...nodes: Konva.Shape[]): void {
        this.stage.container().style.cursor = "pointer";
        nodes.forEach((node) => node.fill("#ededed"));
    }

    private onMouseOut(...nodes: Konva.Shape[]): void {
        this.stage.container().style.cursor = "default";
        nodes.forEach((node) => node.fill("#ffffff"));
    }

    private generateSeekProperties(): SeekBarProperties {
        const seekGroup = this.group.findOne<Konva.Group>("#seekGroup");
        const seekBar = seekGroup.findOne<Konva.Rect>("#seekBar");
        const seekCursor = seekGroup.findOne<Konva.Circle>("#seekCursor");

        const cursorMin = seekBar.x() + seekCursor.radius() / 2;
        const cursorMax = seekBar.x() + seekBar.width() - (seekCursor.radius() / 2);
        const barLength = cursorMax - cursorMin;

        const totalTime = 30000;
        const slidePercentage = barLength / totalTime;
        const totalBlocks = this.summary.endBlockHeight - this.summary.startBlockHeight;

        return { seekBar, seekCursor, cursorMin, cursorMax, barLength, totalTime, slidePercentage, totalBlocks };
    }

    private async createPlayGroup(playWidth: number, playHeight: number): Promise<Konva.Group> {
        const margin = 8;
        const group = new Konva.Group({ id: "playGroup" });
        const background = new Konva.Rect({
            x: -margin,
            y: -margin,
            width: playWidth + (margin * 2),
            height: playHeight + (margin * 2),
        });
        const play = new Konva.Shape({
            id: "play",
            sceneFunc: function (context, shape) {
                context.beginPath();
                context.moveTo(0, 0);
                context.lineTo(0, playHeight);
                context.lineTo(playWidth, playHeight / 2);
                context.closePath();
                context.fillStrokeShape(shape);
            },
            fill: "#ffffff",
            visible: false,
        });
        const pause = new Konva.Shape({
            id: "pause",
            sceneFunc: function (context, shape) {
                context.beginPath();
                context.moveTo(0, 0);
                context.lineTo(0, playHeight);
                context.lineTo((playWidth / 3), playHeight);
                context.lineTo((playWidth / 3), 0);
                context.lineTo(0, 0);
                context.moveTo((playWidth / 3) * 3, 0);
                context.lineTo((playWidth / 3) * 3, playHeight);
                context.lineTo((playWidth / 3) * 2, playHeight);
                context.lineTo((playWidth / 3) * 2, 0);
                context.lineTo((playWidth / 3) * 3, 0);
                context.closePath();
                context.fillStrokeShape(shape);
            },
            fill: "#ffffff",
        });
        const replay = (await this.loadImage(replayIcon)).setAttrs({
            id: "replay",
            y: -(playHeight * 0.1),
            width: playHeight * 0.8,
            height: playHeight,
            visible: false,
        });
        const replayHover = (await this.loadImage(replayIconHover)).setAttrs({
            y: -(playHeight * 0.1),
            width: playHeight * 0.8,
            height: playHeight,
            visible: false,
        });

        group.add(background);
        group.add(play);
        group.add(pause);
        group.add(replay);
        group.add(replayHover);
        group.on("mousemove", () => this.onMouseMove(play, pause));
        group.on("mouseout mouseleave", () => this.onMouseOut(play, pause));
        group.on("click tap", () => {
            if (pause.isVisible()) {
                pause.visible(false);
                play.visible(true);
                this.pause();
                return;
            }

            if (play.isVisible()) {
                pause.visible(true);
                play.visible(false);
                this.play();
                return;
            }

            if (replay.isVisible()) {
                pause.visible(true);
                replay.visible(false);
                this.replay();
            }
        });

        return group;
    }

    private createSeekGroup(seekWidth: number, seekHeight: number): Konva.Group {
        const group = new Konva.Group({ id: "seekGroup" });

        const seekBar = new Konva.Rect({
            id: "seekBar",
            width: seekWidth,
            height: seekHeight / 4,
            fill: "#ffffff",
        });
        seekBar.on("mousemove", () => this.onMouseMove(seekBar));
        seekBar.on("mouseout mouseleave", () => this.onMouseOut(seekBar));
        seekBar.on("mousedown click touchdown touchmove tap", () => {
            const props = this.getSeekProperties();
            const cursorPos = props.seekCursor.getRelativePointerPosition();
            props.seekCursor.x(Math.min(Math.max(seekCursor.x() + cursorPos.x, props.cursorMin), props.cursorMax));
            this.renderFrame(seekCursor.x());
        });

        const seekCursor = new Konva.Circle({
            id: "seekCursor",
            radius: seekHeight / 2,
            fill: "#ffffff",
            x: seekBar.x() + (seekHeight / 4),
            y: seekHeight / 8,
            draggable: true,
        });
        seekCursor.on("mousemove", () => this.onMouseMove(seekCursor));
        seekCursor.on("mouseout mouseleave", () => this.onMouseOut(seekCursor));
        seekCursor.on("dragmove", () => {
            const props = this.getSeekProperties();
            seekCursor.x(Math.min(Math.max(seekCursor.x(), props.cursorMin), props.cursorMax));
            seekCursor.y(seekHeight / 8);
            this.renderFrame(seekCursor.x());
        });

        group.add(seekBar);
        group.add(seekCursor);
        return group;
    }

    private async createSettingsGroup(settingsWidth: number, settingsHeight: number): Promise<Konva.Group> {
        const margin = 8;
        const group = new Konva.Group();
        const background = new Konva.Rect({
            x: -margin,
            y: -margin,
            width: settingsWidth + (margin * 2),
            height: settingsHeight + (margin * 2),
        });
        const gear = (await this.loadImage(gearIcon)).setAttrs({
            width: settingsWidth,
            height: settingsHeight,
        });
        const gearHover = (await this.loadImage(gearIconHover)).setAttrs({
            width: settingsWidth,
            height: settingsHeight,
            visible: false,
        });
        group.add(background);
        group.add(gear);
        group.add(gearHover);
        return group;
    }

    private async loadImage(url: string): Promise<Konva.Image> {
        return new Promise((resolve) => {
            Konva.Image.fromURL(url, (image: Konva.Image) => resolve(image));
        });
    }

    private calculateNextChunks(chunkIndex: number, numberOfChunks: number): number[] {
        if (chunkIndex > this.summary.endBlockHeight) {
            throw new Error("Invalid chunk index");
        }

        let chunks = [];

        for (let nextChunk = chunkIndex; nextChunk <= this.summary.endBlockHeight; nextChunk += this.summary.chunkSize) {
            chunks.push(nextChunk);

            if (chunks.length >= numberOfChunks) {
                break;
            }
        }

        return chunks;
    }
}
