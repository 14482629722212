import Konva from "konva";
import { IFrame } from "konva/lib/types";
import { DeferredRender } from "./deferred_render";
import { Highlightable } from "./highlightable";
import { Coords } from "./types";

export class Zoomies implements DeferredRender {
    private static readonly ZOOM_SCALE = 19;

    private readonly highlightable: Highlightable;
    private readonly layer: Konva.Layer;
    private readonly stage: Konva.Stage;
    private animation: Konva.Animation|null = null;
    private autoZoomEnabled: boolean = false;

    public constructor(highlightable: Highlightable, layer: Konva.Layer, stage: Konva.Stage) {
        this.highlightable = highlightable;
        this.layer = layer;
        this.stage = stage;
    }

    public load(): void {
        this.layer.on("dragstart", () => this.cancel());
        this.stage.on("wheel", (e) => this.onScroll(e));
        this.highlightable.registerOnClickListener((coords: Coords) => this.onClick(coords));
        this.highlightable.registerOnSelectListener((coords: Coords) => this.onSelect(coords));
        this.highlightable.registerOnDeselectListener(() => this.cancel());
    }

    public unload(): void {
        this.layer.off("dragstart");
        this.stage.off("wheel");
        this.cancel();
    }

    public fitIntoStage(stage: Konva.Stage): void {
        // Do nothing
    }

    public enableAutoZoom(): void {
        this.autoZoomEnabled = true;
        const coords = this.highlightable.getSelectedCoords();

        if (coords !== null) {
            this.toCoordsAnimated(coords, Zoomies.ZOOM_SCALE);
        }
    }

    public disableAutoZoom(): void {
        this.autoZoomEnabled = false;
        this.cancel();
        this.highlightable.refresh();
    }

    public zoomToCoords(coords: Coords): void {
        this.highlightable.selectCoords(coords);
        this.cancel();
        this.toCoordsAnimated(coords, Zoomies.ZOOM_SCALE);
    }

    private onClick(coords: Coords): void {
        if (this.autoZoomEnabled) {
            if (this.animation !== null) {
                this.cancel();
            } else {
                this.highlightable.selectCoords(coords);
            }
        }
    }

    private onSelect(coords: Coords): void {
        if (this.autoZoomEnabled) {
            this.cancel();
            this.toCoordsAnimated(coords, Zoomies.ZOOM_SCALE);
        }
    }

    private onScroll(e: Konva.KonvaEventObject<WheelEvent>): void {
        e.evt.preventDefault();

        // Scrolling on view cancels any pending pixel selection
        this.cancel();

        const pointer = this.layer.getRelativePointerPosition();
        this.toCoords(pointer, e.evt.deltaY, 1.12);

        this.highlightable.refresh();
    }

    private toCoords(coords: Coords, direction: number, scale: number): void {
        // Need to determine stage coords before scaling
        const stageCoords = this.convertToStageCoords(coords);
        const oldScale = this.layer.scaleX();
        const newScale = direction < 0 ? oldScale * scale : oldScale / scale;

        // Checking scale inside sensible range
        if (newScale < 0.2 || newScale > 180) {
            return;
        }

        // Zoom the layer by given scale
        this.layer.scale({ x: newScale, y: newScale });

        // Position the layer relative to stage
        const layerCoords = {
            x: (stageCoords.x - this.layer.x()) / oldScale,
            y: (stageCoords.y - this.layer.y()) / oldScale,
        };
        const newPos = {
            x: stageCoords.x - layerCoords.x * newScale,
            y: stageCoords.y - layerCoords.y * newScale,
        };
        this.layer.position(newPos);
    }

    private toCoordsAnimated(coords: Coords, scale: number): void {
        if (this.animation !== null) {
            throw new Error("Cancel current animation before starting new one");
        }

        const centerX: number = coords.x + 0.5;
        const centerY: number = coords.y + 0.5;

        this.animation = new Konva.Animation((frame?: IFrame) => {
            if (!frame) {
                return;
            }

            // Bail if failed to focus in 10 seconds
            if (frame.time > 10000) {
                this.cancel();
                return;
            }

            const targetX =  (this.layer.width() / 2) - (centerX * this.layer.scaleX());
            const targetY =  (this.layer.height() / 2) - (centerY * this.layer.scaleY());
            const diffX = (this.layer.x() - targetX) / (20 * frame.timeDiff);
            const diffY = (this.layer.y() - targetY) / (20 * frame.timeDiff);
            const scaleDiff = this.layer.scaleX() - scale;

            let focused = true;

            if (Math.abs(diffX) > 0.05 || Math.abs(diffY) > 0.05) {
                const newPos = {
                    x: this.layer.x() - diffX,
                    y: this.layer.y() - diffY,
                };
                this.layer.position(newPos);
                focused = false;
            }

            // Zooming to approx 1% of target scale
            if (Math.abs(scaleDiff) > (scale * 0.01)) {
                this.toCoords(coords, scaleDiff, 1 + (0.002 * frame.timeDiff));
                focused = false;
            }

            if (focused) {
                this.cancel();
            }
        }, this.layer);

        this.animation.start();
    }

    private cancel(): void {
        this.animation?.stop();
        this.animation = null;
    }

    private convertToStageCoords(coords: Coords): Coords {
        const scaleX: number = this.layer.scaleX();
        const scaleY: number = this.layer.scaleY();
        const viewX: number = 0 - (this.layer.x() / scaleX);
        const viewY: number = 0 - (this.layer.y() / scaleY);
        return {
            x: (coords.x - viewX) * scaleX,
            y: (coords.y - viewY) * scaleY,
        };
    }
}
