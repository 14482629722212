import { Component, ReactNode } from "react";
import loadingBackground from "../../assets/images/loading-background.svg";
import "./loading_overlay.css";

interface LoadingOverlayProps {
    show: boolean;
}

export class LoadingOverlay extends Component<LoadingOverlayProps> {
    render(): ReactNode {
        return (
            <div id="loading-overlay-container" className={(this.props.show) ? "" : "loading-fadeout"}>
                <img id="loading-overlay"
                     className={(this.props.show) ? "loading-fadein" : ""}
                     src={loadingBackground}
                     alt=""
                />
            </div>
        );
    }
}
