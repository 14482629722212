import Grid from "@mui/material/Grid";
import { Mutex } from "async-mutex";
import { Component, Fragment, ReactNode } from "react";
import { ApiClient } from "../../components/clients/api_client";
import { Spinner } from "../../components/loading/spinner";
import { BlockTimer } from "./block_timer";
import { InfoPanel } from "./info_panel";

interface Info {
    blockHeight: number|null;
    transactions: number|null;
    blockTime: number|null;
    lastBlockTimestamp: number|null;
    peers: number|null;
    blockNewId: string|null;
    transactionNewId: string|null;
}

export class ChainInfo extends Component<any, Info> {
    state: Info = {
        blockHeight: null,
        transactions: null,
        blockTime: null,
        lastBlockTimestamp: null,
        peers: null,
        blockNewId: null,
        transactionNewId: null,
    };
    private lock: Mutex = new Mutex();

    public async componentDidMount(): Promise<void> {
        await this.lock.runExclusive(async () => {
            const nodeInfo = await ApiClient.getInstance().queryNodeInfo();
            const lastBlock = await ApiClient.getInstance().queryLastBlock();
            const totalTransactions = await ApiClient.getInstance().queryTotalTransactions();
            const blockNewId = await ApiClient.getInstance().subscribeBlockNew((block) => this.setState({
                ...this.state,
                blockHeight: block.number,
                lastBlockTimestamp: block.timestamp,
            }));
            const transactionNewId = await ApiClient.getInstance().subscribeTransactionNew(() => this.setState({
                ...this.state,
                transactions: (this.state.transactions ?? 0) + 1,
            }));
            this.setState({
                ...this.state,
                blockHeight: lastBlock.number,
                transactions: totalTransactions ?? 0,
                blockTime: nodeInfo.blockTime,
                lastBlockTimestamp: lastBlock.timestamp,
                peers: nodeInfo.peerCount,
                blockNewId: blockNewId,
                transactionNewId: transactionNewId,
            });
        });
    }

    public async componentWillUnmount(): Promise<void> {
        await this.lock.runExclusive(async () => {
            if (this.state.blockNewId) {
                await ApiClient.getInstance().unsubscribeBlockNew(this.state.blockNewId);
            }

            if (this.state.transactionNewId) {
                await ApiClient.getInstance().unsubscribeTransactionNew(this.state.transactionNewId);
            }
        });
    }

    public render(): ReactNode {
        const spinner = <Spinner width={30} height={30} />;
        return (
            <Fragment>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <InfoPanel title={"Block Height"}>{this.state.blockHeight?.toLocaleString() ?? spinner}</InfoPanel>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <InfoPanel title={"Transactions"}>{this.state.transactions?.toLocaleString() ?? spinner}</InfoPanel>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <InfoPanel title={"Next Block"}>
                        {
                            (this.state.blockTime && this.state.lastBlockTimestamp)
                                ? <BlockTimer key={this.state.lastBlockTimestamp} blockTime={this.state.blockTime} lastBlockTimestamp={this.state.lastBlockTimestamp} />
                                : spinner
                        }
                    </InfoPanel>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <InfoPanel title={"Peers"}>{this.state.peers ?? spinner}</InfoPanel>
                </Grid>
            </Fragment>
        );
    }
}
