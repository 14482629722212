import { Component, ReactNode } from "react";
import lisk from "../../assets/images/lisk-logo.png";
import "./as_seen_on.css";

export class AsSeenOn extends Component {
    componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#as-seen-on-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));
    }

    render(): ReactNode {
        return (
            <div className="container">
                <section id="as-seen-on-row">
                    <div className="hidden">
                        <h4 className="rgb-black">As seen on</h4>
                        <p>
                            <a href="https://lisk.com/grant-program">
                                <img src={lisk} alt=""/>
                            </a>
                        </p>
                    </div>
                </section>
            </div>
        );
    }
}
