import { Box, styled } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Mutex } from "async-mutex";
import { Component, Fragment, ReactNode } from "react"
import ReactConfetti from "react-confetti";
import { formatEther } from "viem";
import { CanvasCache } from "../../components/cache/canvas_cache";
import { ApiClient } from "../../components/clients/api_client";
import { LotteryWinnings } from "../../components/clients/types";
import { WalletHandler } from "../../components/wallet/wallet_handler";
import MoneyBagAnimation from "../../assets/animations/money-bag.mp4";

const VideoContainer = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& video": {
        width: "100%",
        maxHeight: "50vh",
        objectFit: "contain",
        paddingRight: "20px",
    },
});

interface LotteryNotificationState {
    showDialog: boolean;
    showConfetti: boolean;
    ethWinnings: LotteryWinnings[];
    rgbWinnings: LotteryWinnings[];
    isAuthenticatedId: string|null;
    lotteryWinningsId: string|null;
    address: string|null;
}

export class LotteryNotification extends Component<any, LotteryNotificationState> {
    public state: LotteryNotificationState = {
        showDialog: false,
        showConfetti: false,
        ethWinnings: [],
        rgbWinnings: [],
        isAuthenticatedId: null,
        lotteryWinningsId: null,
        address: null,
    };
    private lock: Mutex = new Mutex();

    public async componentDidMount(): Promise<void> {
        await this.lock.runExclusive(async () => {
            const isAuthenticatedId = WalletHandler.getInstance().subscribeIsAuthenticated(async (isAuthenticated: boolean) => {
                // Only subscribe once
                if (!isAuthenticated || this.state.lotteryWinningsId !== null) {
                    return;
                }

                const client = await WalletHandler.getInstance().getWalletClient();
                const address = client.account.address;
                const lotteryWinningsId = await this.subscribeLotteryWinnings(address);

                let ethWinnings: LotteryWinnings[] = [];
                let rgbWinnings: LotteryWinnings[] = [];

                for (const winnings of await ApiClient.getInstance().queryPendingWinnings()) {
                    const canvas = await CanvasCache.getInstance().getCanvas(winnings.canvasId);

                    if (canvas === null) {
                        throw new Error(`Failed to find canvas ${winnings.canvasId}`);
                    }

                    switch (canvas.lotterySettings.costPerPixel.currency) {
                        // ETH
                        case 0:
                            ethWinnings.push(winnings);
                            break;

                        // RGB
                        case 1:
                            rgbWinnings.push(winnings);
                            break;

                        default:
                            throw new Error(`Unsupported currency ${canvas.lotterySettings.costPerPixel.currency}`);
                    }
                }

                this.setState((state) => ({
                    ...state,
                    showDialog: ethWinnings.length !== 0 || rgbWinnings.length !== 0,
                    showConfetti: ethWinnings.length !== 0 || rgbWinnings.length !== 0,
                    ethWinnings: ethWinnings,
                    rgbWinnings: rgbWinnings,
                    lotteryWinningsId: lotteryWinningsId,
                    address: address,
                }));
            });

            this.setState((state) => ({ ...state, isAuthenticatedId: isAuthenticatedId }));
        });
    }

    public async componentWillUnmount(): Promise<void> {
        await this.lock.runExclusive(async () => {
            if (this.state.isAuthenticatedId !== null) {
                WalletHandler.getInstance().unsubscribeIsAuthenticated(this.state.isAuthenticatedId);
            }

            if (this.state.address !== null && this.state.lotteryWinningsId !== null) {
                await ApiClient.getInstance().unsubscribeLotteryWinnings(this.state.address, this.state.lotteryWinningsId);
            }
        });
    }

    public render(): ReactNode {
        return (
            <Fragment>
                <ReactConfetti
                    run={this.state.showConfetti}
                    recycle={this.state.showDialog}
                    numberOfPieces={800}
                    wind={0}
                    gravity={20}
                    initialVelocityX={4}
                    initialVelocityY={20}
                    opacity={90}
                    friction={0.1}
                    tweenDuration={100000}
                    onConfettiComplete={() => this.setState((state) => ({ ...state, showConfetti: false }))}
                    style={{ zIndex: 1299 }}
                />
                <Dialog open={this.state.showDialog}>
                    <DialogContent>
                        <h1 className={"rgb-blue"} style={{ textAlign: "center" }}>YOU WON!!!</h1>
                        <VideoContainer>
                            <video
                                autoPlay={true}
                                playsInline
                                loop
                                muted
                                // onCanPlay={() => this.setState({ running: true })}
                                controlsList="nodownload" // Disable download button
                            >
                                <source src={MoneyBagAnimation} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </VideoContainer>
                        <p style={{ textAlign: "center" }}>Congratulations, you have won:</p>
                        {this.state.ethWinnings.length === 0 ? null : <h3 style={{ textAlign: "center" }}>{formatEther(this.sumWinnings(this.state.ethWinnings))} ETH</h3>}
                        {this.state.ethWinnings.length === 0 || this.state.rgbWinnings.length === 0 ? null : <p style={{ textAlign: "center" }}>AND</p>}
                        {this.state.rgbWinnings.length === 0 ? null : <h3 style={{ textAlign: "center" }}>{formatEther(this.sumWinnings(this.state.rgbWinnings))} RGB</h3>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={async () => {
                            this.setState((state) => ({ ...state, showDialog: false }));
                            // Don't block on confirmation when closing dialog
                            await this.confirmNotification();
                        }}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }

    private async subscribeLotteryWinnings(address: string): Promise<string> {
        return await ApiClient.getInstance().subscribeLotteryWinnings(address, async (winnings: LotteryWinnings) => {
            const canvas = await CanvasCache.getInstance().getCanvas(winnings.canvasId);

            if (canvas === null) {
                throw new Error(`Failed to find canvas ${winnings.canvasId}`);
            }

            this.setState((state) => {
                switch (canvas.lotterySettings.costPerPixel.currency) {
                    // ETH
                    case 0:
                        return {
                            ...state,
                            showDialog: true,
                            showConfetti: true,
                            ethWinnings: state.ethWinnings.concat(winnings),
                        };

                    // RGB
                    case 1:
                        return {
                            ...state,
                            showDialog: true,
                            showConfetti: true,
                            rgbWinnings: state.rgbWinnings.concat(winnings),
                        };

                    default:
                        throw new Error(`Unsupported currency ${canvas.lotterySettings.costPerPixel.currency}`);
                }
            });
        });
    }

    private async confirmNotification(): Promise<void> {
        for (const winnings of this.state.ethWinnings) {
            await ApiClient.getInstance().confirmWinningsNotified(winnings.canvasId);
            this.setState((state) => ({ ...state, ethWinnings: state.ethWinnings.filter((current) => current.canvasId !== winnings.canvasId)}));
        }

        for (const winnings of this.state.rgbWinnings) {
            await ApiClient.getInstance().confirmWinningsNotified(winnings.canvasId);
            this.setState((state) => ({ ...state, rgbWinnings: state.rgbWinnings.filter((current) => current.canvasId !== winnings.canvasId)}));
        }
    }

    private sumWinnings(winnings: LotteryWinnings[]): bigint {
        return winnings.reduce((prev, curr) => prev + BigInt(curr.amount), BigInt(0));
    }
}
