import assert from "assert";
import { Buffer } from "buffer";
import { Pixels, ViewProperties } from "./types";

export function deserialiseColourPalette(colours: string): string[] {
    return Buffer.from(colours, "hex").reduce((previousValue: string[], currentValue: number, currentIndex: number, array: Uint8Array) => {
        if (currentIndex % 3 === 2) {
            const colour = "#" + array[currentIndex - 2].toString(16).padStart(2, "0") +
                array[currentIndex - 1].toString(16).padStart(2, "0") +
                array[currentIndex].toString(16).padStart(2, "0");
            previousValue.push(colour);
        }

        return previousValue;
    }, []);
}

export function deserialiseCoords(coords: string): number[] {
    return Buffer.from(coords, "hex").reduce((previousValue: number[], currentValue: number, currentIndex: number) => {
        // 24 bit coordinate packed with little endianness
        const index = Math.floor(currentIndex / 3);
        previousValue[index] = (previousValue[index] ?? 0) | (currentValue << ((currentIndex % 3) * 8));
        return previousValue;
    }, []);
}

export function deserialiseColours(colours: string): number[] {
    return Buffer.from(colours, "hex").reduce((previousValue: number[], currentValue: number) => {
        // 4 bit colour encoding (each byte packs two colours)
        previousValue.push(currentValue & 0x0F);
        previousValue.push((currentValue >> 4) & 0x0F);
        return previousValue;
    }, []);
}

export function serialiseCoords(coords: number[]): Buffer {
    const serialised = coords.reduce((previousValue: number[], currentValue: number) => {
        // 24 bit coordinate packed with little endianness
        previousValue.push(currentValue & 0xFF);
        previousValue.push((currentValue >> 8) & 0xFF);
        previousValue.push((currentValue >> 16) & 0xFF);
        return previousValue;
    }, []);
    return Buffer.from(serialised);
}

export function serialiseColours(colours: number[]): Buffer {
    const serialised = colours.reduce((previousValue: number[], currentValue: number, currentIndex: number) => {
        if (currentIndex % 2) {
            // 4 bit colour encoding (each byte packs two colours)
            const lastIndex = previousValue.length - 1;
            previousValue[lastIndex] = previousValue[lastIndex] | ((currentValue & 0x0F) << 4);
            return previousValue;
        }

        previousValue.push(currentValue & 0x0F);
        return previousValue;
    }, []);
    return Buffer.from(serialised);
}

export function decodePixels(coords: number[], colours: number[], properties: ViewProperties): Pixels {
    const xCoords: number[] = [];
    const yCoords: number[] = [];

    for (const coord of coords) {
        xCoords.push(coord % properties.width);
        yCoords.push(Math.floor(coord / properties.width));
    }

    return { xCoords, yCoords, colours: colours.map((colour) => properties.colourPalette[colour]).slice(0, coords.length) };
}

export function decodeViewRgba(view: string, properties: ViewProperties): Uint8ClampedArray {
    const paletteRgba: Record<string, number[]> = {};
    const decoded = new Uint8ClampedArray(properties.width * properties.height * 4);

    // Decoding colour palette into array of RGBA values
    properties.colourPalette.forEach((colour) => {
        const rgb = colour.substring(1).match(/.{1,2}/g)?.map((hex) => parseInt(hex, 16))
        assert(rgb !== undefined);
        // Alpha is manually set to 255
        rgb.push(255);
        paletteRgba[colour] = rgb;
    });

    // View is base64 encoded array of 4bit colours (where value refers to index of palette in properties)
    Buffer.from(view, "base64").forEach((value: number, index: number) => {
        // Colour offset 0
        const offset1 = (index * 2);
        const rgba1 = paletteRgba[properties.colourPalette[value & 0x0F]];
        decoded.set(rgba1, offset1 * 4);

        // Colour offset 1
        const offset2 = (index * 2) + 1;
        const rgba2 = paletteRgba[properties.colourPalette[(value >> 4) & 0x0F]];
        decoded.set(rgba2, offset2 * 4);
    });

    return decoded;
}
