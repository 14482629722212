import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Mutex } from "async-mutex";
import { Component, Fragment, ReactNode } from "react";
import { ApiClient } from "../../components/clients/api_client";
import { Spinner } from "../../components/loading/spinner";
import { AccountBalance, WalletHandler } from "../../components/wallet/wallet_handler";
import { LastTableRow } from "./last_table_row";
import { Title } from "./title";

interface InfoState {
    balances: AccountBalance[]|null;
    blockNewId: string|null;
}

const spinner = <Spinner width={14} height={14} />;

export class AccountInfo extends Component<any, InfoState> {
    state: InfoState = {
        balances: null,
        blockNewId: null,
    };
    private lock: Mutex = new Mutex();

    public async componentDidMount(): Promise<void> {
        await this.lock.runExclusive(async () => {
            const blockNewId = await ApiClient.getInstance().subscribeBlockNew(async () => {
                this.setState({
                    ...this.state,
                    balances: await WalletHandler.getInstance().getAccountBalances(),
                });
            });
            const balances = await WalletHandler.getInstance().getAccountBalances();
            this.setState({
                ...this.state,
                blockNewId: blockNewId,
                balances: balances,
            });
        });
    }

    public async componentWillUnmount(): Promise<void> {
        await this.lock.runExclusive(async () => {
            if (this.state.blockNewId) {
                await ApiClient.getInstance().unsubscribeBlockNew(this.state.blockNewId);
            }
        });
    }

    public render(): ReactNode {
        return (
            <Fragment>
                <Title>Account</Title>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>Balance</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { this.state.balances === null ?
                            <LastTableRow key="0">
                                <TableCell>{spinner}</TableCell>
                                <TableCell>{spinner}</TableCell>
                            </LastTableRow> :
                            this.state.balances.map((balance, index) => {
                                if ((index + 1)=== this.state.balances?.length) {
                                    return <LastTableRow key={index}>
                                        <TableCell>{balance.address}</TableCell>
                                        <TableCell>{balance.balance}</TableCell>
                                    </LastTableRow>
                                } else {
                                    return <TableRow key={index}>
                                        <TableCell>{balance.address}</TableCell>
                                        <TableCell>{balance.balance}</TableCell>
                                    </TableRow>
                                }
                            })
                        }
                    </TableBody>
                </Table>
            </Fragment>
        );
    }
}
