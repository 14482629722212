import assert from "assert";
import { Mutex } from "async-mutex";
import { Buffer } from "buffer";
import { v4 as uuid } from "uuid";
import { app } from "../../config/app";
import { isEmpty, ResolvablePromise } from "../util/helpers";
import { deserialiseColourPalette, deserialiseColours, deserialiseCoords } from "./codec";
import { Account, Block, Canvas, CanvasId, ContractInfo, LotteryWinnings, NodeInfo, TimelapseChunk, Timelapses, TimelapseSummary, Transaction, ViewChanged } from "./types";

type SubscriptionListener<T = unknown> = (message?: T) => void;

type CloseListener = (error?: Error) => void;

interface Request {
    payload: any;
    promise: ResolvablePromise<any>;
}

export class ApiClient {
    private static instance: ApiClient|null = null;
    private websocket: WebSocket;
    private accessToken: ResolvablePromise<string|null>;
    private requests: Record<number, Request> = {};
    private subscriptions: Record<string, Promise<number>> = {};
    private listeners: Record<number, Record<string, SubscriptionListener<any>>> = {};
    private onCloseListeners: CloseListener[] = [];
    private ready: boolean = false;
    private id: number = 0;
    private lock: Mutex = new Mutex();

    private constructor() {
        this.websocket = this.createWebSocket(app.WS_URL);
        this.accessToken = new ResolvablePromise<string|null>();
    }

    public static getInstance(): ApiClient {
        if (ApiClient.instance === null) {
            ApiClient.instance = new ApiClient();
        }

        assert(ApiClient.instance !== null);
        return ApiClient.instance;
    }

    public setAccessToken(accessToken: string): void {
        this.refreshAccessToken();
        this.accessToken.resolve(accessToken);
    }

    public clearAccessToken(): void {
        this.refreshAccessToken();
        this.accessToken.resolve(null);
    }

    public refreshAccessToken(): void {
        // Refresh promise so that can be resolved again
        if (this.accessToken.isResolved()) {
            this.accessToken = new ResolvablePromise<string|null>();
        }
    }

    public subscribeOnClose(listener: CloseListener): void {
        // Notifies when websocket closes and subscriptions are nuked - only notified once, must subscribe again
        this.onCloseListeners.push(listener);
    }

    public async subscribeBlockNew(listener: (block: Block) => void): Promise<string> {
        return this.subscribe<Block>(["block:new"], (message) => {
            if (message) {
                listener(message);
            }
        });
    }

    public async unsubscribeBlockNew(listenerId: string): Promise<void> {
        await this.unsubscribe(["block:new"], listenerId);
    }

    public async subscribeTransactionNew(listener: (transaction: Transaction) => void): Promise<string> {
        return this.subscribe<Transaction>(["transaction:new"], (message) => {
            if (message) {
                listener(message);
            }
        });
    }

    public async unsubscribeTransactionNew(listenerId: string): Promise<void> {
        await this.unsubscribe(["transaction:new"], listenerId);
    }

    public async subscribeCanvasStarted(listener: (canvasId: number) => void): Promise<string> {
        return this.subscribe<CanvasId>(["canvas:started"], (message) => {
            if (message) {
                listener(message.canvasId);
            }
        });
    }

    public async unsubscribeCanvasStarted(listenerId: string): Promise<void> {
        await this.unsubscribe(["canvas:started"], listenerId);
    }

    public async subscribeCanvasCompleted(listener: (canvasId: number) => void): Promise<string> {
        return this.subscribe<CanvasId>(["canvas:completed"], (message) => {
            if (message) {
                listener(message.canvasId);
            }
        });
    }

    public async unsubscribeCanvasCompleted(listenerId: string): Promise<void> {
        await this.unsubscribe(["canvas:completed"], listenerId);
    }

    public async subscribeViewChanged(listener: (view: ViewChanged) => void): Promise<string> {
        return this.subscribe<{canvasId: number, coords: string, colours: string}>(["view:modified"], (message) => {
            if (message) {
                listener({
                    ...message,
                    coords: deserialiseCoords(message.coords),
                    colours: deserialiseColours(message.colours),
                });
            }
        });
    }

    public async unsubscribeViewChanged(listenerId: string): Promise<void> {
        await this.unsubscribe(["view:modified"], listenerId);
    }

    public async subscribeLotteryWinnings(address: string, listener: (winnings: LotteryWinnings) => void): Promise<string> {
        return this.subscribe<LotteryWinnings>([`lottery:winnings:${address}`], (message) => {
            if (message) {
                listener(message);
            }
        });
    }

    public async unsubscribeLotteryWinnings(address: string, listenerId: string): Promise<void> {
        await this.unsubscribe([`lottery:winnings:${address}`], listenerId);
    }

    public async createSubscription(token: string, email: string, address: string, signature: string): Promise<void> {
        const response = await this.post("/v1/subscribe", {
            token,
            email,
            address: Buffer.from(address).toString("base64"),
            signature: Buffer.from(signature).toString("base64"),
        });

        if (!response) {
            throw new Error("Failed to subscribe");
        }
    }

    public async verifySubscription(token: string): Promise<string|null> {
        const response = await this.post("/v1/subscribe/verify", { token });

        if (!response) {
            return null;
        }

        const decoded = await response.json();
        return decoded.token;
    }

    public async querySubscriptionVerified(address: string): Promise<boolean|null> {
        const encodedAddress = Buffer.from(address).toString("base64");
        const response = await this.fetch(`/v1/subscribe/${encodedAddress}/info`);

        if (!response) {
            return null;
        }

        const decoded = await response.json();
        return decoded.verified;
    }

    public async authenticate(address: string, signature: string): Promise<string|null> {
        const response = await this.post("/v1/beta/authenticate", {
            address: Buffer.from(address).toString("base64"),
            signature: Buffer.from(signature).toString("base64"),
        });

        if (!response) {
            return null;
        }

        const decoded = await response.json();
        return decoded.token;
    }

    public async queryNodeInfo(): Promise<NodeInfo> {
        const response = await this.authFetch("/v1/nodes/info");

        if (!response) {
            throw new Error("Failed to query node info");
        }

        return await response.json();
    }

    public async queryContractInfo(): Promise<ContractInfo> {
        const response = await this.authFetch("/v1/contracts/info");

        if (!response) {
            throw new Error("Failed to query contract info");
        }

        return await response.json();
    }

    public async queryLastBlock(): Promise<Block> {
        const response = await this.authFetch("/v1/blocks/last");

        if (!response) {
            throw new Error("Failed to query last block");
        }

        return await response.json();
    }

    public async queryActiveCanvases(): Promise<number[]> {
        const response = await this.authFetch("/v1/canvases/active");

        if (!response) {
            return [];
        }

        const decoded = await response.json();
        return decoded.canvasIds;
    }

    public async queryPendingCanvases(): Promise<number[]> {
        const response = await this.authFetch("/v1/canvases/pending");

        if (!response) {
            return [];
        }

        const decoded = await response.json();
        return decoded.canvasIds;
    }

    public async queryCanvas(canvasId: number): Promise<Canvas|null> {
        const response = await this.authFetch(`/v1/canvases/${canvasId}/properties`);

        if (!response) {
            return null;
        }

        const canvas = await response.json();
        return {
            ...canvas,
            canvasProps: {
                ...canvas.canvasProps,
                colourPalette: deserialiseColourPalette(canvas.canvasProps.colourPalette),
            }
        };
    }

    public async queryCanvasView(canvasId: number): Promise<string|null> {
        const response = await this.authFetch(`/v1/canvases/${canvasId}/view`);

        if (!response) {
            return null;
        }

        return response.text();
    }

    public async queryAccount(accessToken?: string): Promise<Account|null> {
        const response = await this.authFetch("/v1/beta/account", accessToken);

        if (response === null) {
            return null;
        }

        return await response.json();
    }

    public async queryTotalTransactions(accessToken?: string): Promise<number|null> {
        const response = await this.authFetch("/v1/transactions/total", accessToken);

        if (response === null) {
            return null;
        }

        const decoded = await response.json();
        return decoded.total;
    }

    public async queryTransactions(accessToken?: string): Promise<Transaction[]|null> {
        const response = await this.authFetch("/v1/transactions", accessToken);

        if (response === null) {
            return null;
        }

        const decoded = await response.json();
        return decoded.transactions.map((transaction: { fee: number }) => {
            return {
                ...transaction,
                fee: "", // convertBeddowsToLSK(transaction.fee.toString()),
            };
        });
    }

    public async queryTimelapses(): Promise<Timelapses|null> {
        const response = await this.authFetch("/v1/timelapses");

        if (response === null) {
            return null;
        }

        const timelapses = await response.json();
        return {
            ...timelapses,
            timelapses: timelapses.timelapses.map((timelapse: any) => {
                return {
                    ...timelapse,
                    colourPalette: deserialiseColourPalette(timelapse.colourPalette),
                };
            }),
        };
    }

    public async queryTimelapse(canvasId: number): Promise<TimelapseSummary|null> {
        const response = await this.authFetch(`/v1/timelapses/${canvasId}`);

        if (response === null) {
            return null;
        }

        const summary = await response.json();
        return { ...summary, colourPalette: deserialiseColourPalette(summary.colourPalette), canvasId };
    }

    public async queryTimelapseChunk(canvasId: number, blockHeight: number): Promise<TimelapseChunk|null> {
        const response = await this.authFetch(`/v1/timelapses/${canvasId}/chunks/${blockHeight}`);

        if (response === null) {
            return null;
        }

        return await response.json();
    }

    public async queryPendingWinnings(): Promise<LotteryWinnings[]> {
        const response = await this.authFetch(`/v1/lottery/winnings/pending`);

        if (response === null) {
            return [];
        }

        return (await response.json()).winnings;
    }

    public async confirmWinningsNotified(canvasId: number): Promise<void> {
        const response = await this.authPost("/v1/lottery/winnings/confirm", { canvasId });

        if (!response) {
            throw new Error("Failed to confirm");
        }
    }

    private async authFetch(url: string, accessToken?: string): Promise<Response|null> {
        const token = accessToken ?? await this.accessToken.promise();

        if (!token) {
            throw new Error("Access token not set");
        }

        return this.fetch(`${url}?_at=${token}`);
    }

    private async fetch(url: string): Promise<Response|null> {
        return fetch(`${app.API_URL}${url}`).catch(() => null);
    }

    private async authPost(url: string, data: object, accessToken?: string): Promise<Response|null> {
        const token = accessToken ?? await this.accessToken.promise();

        if (!token) {
            throw new Error("Access token not set");
        }

        return this.post(`${url}?_at=${token}`, data);
    }

    private async post(url: string, data: object): Promise<Response|null> {
        return fetch(`${app.API_URL}${url}`, {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(data),
        }).then((response: Response) => response.ok ? response: null).catch(() => null);
    }

    private async subscribe<T = unknown>(params: Array<any>, listener: SubscriptionListener<T>): Promise<string> {
        return this.lock.runExclusive(async () => {
            const tag = this.hashParams(params);

            if (!(tag in this.subscriptions)) {
                this.subscriptions[tag] = Promise.all(params).then((param) => {
                    return this.send<number>("subscribe", param);
                });
            }

            const subscriptionId = await this.subscriptions[tag];

            if (!(subscriptionId in this.listeners)) {
                this.listeners[subscriptionId] = {};
            }

            const listenerId = uuid();
            this.listeners[subscriptionId][listenerId] = listener;
            return listenerId;
        });
    }

    private async unsubscribe(params: Array<any>, listenerId: string): Promise<void> {
        await this.lock.runExclusive(async () => {
            const tag = this.hashParams(params);

            if (!(tag in this.subscriptions)) {
                return;
            }

            const subscriptionId = await this.subscriptions[tag];

            // Remove listener
            if (subscriptionId in this.listeners) {
                delete this.listeners[subscriptionId][listenerId];
            }

            // If no more listeners unsubscribe
            if (isEmpty(this.listeners[subscriptionId])) {
                await this.send("unsubscribe", [subscriptionId]);
                delete this.subscriptions[tag];
            }
        });
    }

    private send<T = unknown>(method: string, params?: Array<any>): Promise<T> {
        const rid = this.id++;
        const promise = new ResolvablePromise<T>();
        const payload = JSON.stringify({
            method: method,
            params: params,
            id: rid,
            jsonrpc: "2.0"
        });

        this.requests[rid] = { promise, payload };

        if (this.ready) {
            this.websocket.send(payload);
        }

        return promise.promise();
    }

    private createWebSocket(url: string): WebSocket {
        const websocket = new WebSocket(url);
        websocket.onopen = () => {
            this.ready = true;
            for (const id in this.requests) {
                websocket.send(this.requests[id].payload);
            }
        };
        websocket.onmessage = (messageEvent: MessageEvent) => {
            const data = String(messageEvent.data);
            const result = JSON.parse(data);

            if (result.id != null) {
                const request = this.requests[result.id];
                delete this.requests[result.id];

                if (!result.error && result.result !== undefined) {
                    request.promise.resolve(result.result);
                } else if (result.error) {
                    const error = new Error(result.error.message || "Unknown error");
                    request.promise.reject(error);
                } else {
                    request.promise.reject(new Error("Unknown error"));
                }
            } else if (result.method === "subscribe") {
                if (result.params.id in this.listeners) {
                    for (const listenerId in this.listeners[result.params.id]) {
                        this.listeners[result.params.id][listenerId](result.params.result);
                    }
                }
            } else {
                console.warn(`Received invalid message (${data})`);
            }
        };
        websocket.onerror = (error) => {
            const exception = (error as ErrorEvent).error;
            console.error(exception);
            this.close(exception).then(() => {
                this.websocket = this.createWebSocket(url);
            });
        };
        return websocket;
    }

    private async close(error?: Error): Promise<void> {
        if (this.websocket === null) {
            return;
        }

        this.ready = false;

        // Fail pending requests
        for (const id in this.requests) {
            try {
                this.requests[id].promise.reject(error ?? new Error("WebSocket closed"));
            } catch { }
        }

        // Nuke pending requests and listeners
        this.requests = {};
        this.subscriptions = {};
        this.listeners = {};

        // Wait until we have connected before trying to disconnect
        if (this.websocket.readyState === WebSocket.CONNECTING) {
            await (new Promise((resolve) => {
                this.websocket.onopen = function() {
                    resolve(true);
                };

                this.websocket.onerror = function() {
                    resolve(false);
                };
            }));
        }

        // Hangup (https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent#Status_codes)
        this.websocket.close(1000);
        this.notifyClosed(error);
    }

    private notifyClosed(error?: Error): void {
        for (const listener of this.onCloseListeners) {
            try {
                listener(error);
            } catch { }
        }
    }

    private hashParams(params: Array<any>): string {
        let hash = "";

        for (const param of params) {
            hash += `${this.hashParam(param)},`;
        }

        return hash.slice(0, -1);
    }

    private hashParam(param: any): string {
        if (typeof param === "string") {
            return param;
        } else if (typeof param === "number" || typeof param === "boolean") {
            return String(param);
        } else if (Array.isArray(param)) {
            return this.hashParams(param);
        } else if (typeof param === "object") {
            let hash = "";

            for (const key in param) {
                hash += `${this.hashParam(key)}:${this.hashParam(param[key])},`;
            }

            return hash.slice(0, -1);
        } else {
            throw new Error(`Unsupported param type ${typeof param}`);
        }
    }
}
