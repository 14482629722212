import assert from "assert";
import Konva from "konva";
import { DD } from "konva/lib/DragAndDrop";
import { Vector2d } from "konva/lib/types";
import { ViewProperties } from "../clients/types";
import { DeferredRender } from "./deferred_render";
import { Coords } from "./types";

export class Canvas implements DeferredRender {
    private readonly properties: ViewProperties;
    private readonly htmlCanvas: HTMLCanvasElement;
    private readonly layer: Konva.Layer;
    private readonly stage: Konva.Stage;
    private readonly image: Konva.Image;
    private dragEnabled: boolean = true;
    private dragButtons: number[] = [0, 1, 2];
    private lastCenter: Vector2d|null = null;
    private lastDist: number = 0;
    private dragStopped: boolean = false;

    public constructor(
        properties: ViewProperties,
        htmlCanvas: HTMLCanvasElement,
        layer: Konva.Layer,
        stage: Konva.Stage
    ) {
        this.properties = properties;
        this.htmlCanvas = htmlCanvas;
        this.layer = layer;
        this.stage = stage;
        this.image = new Konva.Image({
            x: 0,
            y: 0,
            image: this.htmlCanvas,
            width: this.htmlCanvas.width,
            height: this.htmlCanvas.height,
        });
    }

    public load(): void {
        // Allows other events to emit while dragging - need it for multi-touch scaling
        Konva.hitOnDragEnabled = true;

        this.layer.add(this.image);
        this.stage.on("mousedown touchstart", (e) => this.onDrag(e));
        this.stage.on("touchmove", (e) => this.onTouchMove(e));
        this.stage.on("touchend", () => this.onTouchEnd());
    }

    public unload(): void {
        this.image.remove();
        this.stage.off("mousedown touchstart touchmove touchend");
    }

    public fitIntoStage(stage: Konva.Stage): void {
        const scale: number = Math.max(stage.width() / this.htmlCanvas.width, stage.height() / this.htmlCanvas.height);
        this.layer.scale({ x: scale, y: scale });
    }

    public getProperties(): ViewProperties {
        return this.properties;
    }

    public getColour(coords: Coords): string {
        const imageData = this.getContext().getImageData(0, 0, this.htmlCanvas.width, this.htmlCanvas.height).data;
        const offset = (coords.x + coords.y * this.htmlCanvas.width) * 4;
        return "#" + imageData[offset].toString(16).padStart(2, "0")
            + imageData[offset + 1].toString(16).padStart(2, "0")
            + imageData[offset + 2].toString(16).padStart(2, "0");
    }

    public updateCanvas(xCoords: number[], yCoords: number[], colours: string[]): void {
        if (xCoords.length !== yCoords.length || xCoords.length !== colours.length) {
            throw new Error("Invalid pixels");
        }

        const ctx = this.getContext();

        for (let i = 0; i < xCoords.length; i++) {
            ctx.fillStyle = colours[i];
            ctx.fillRect(xCoords[i], yCoords[i], 1, 1);
        }

        this.layer.draw();
    }

    public copyCanvas(source: CanvasImageSource): void {
        this.getContext().drawImage(source, 0, 0);
    }

    public enableDrag(): void {
        this.dragEnabled = true;
    }

    public disableDrag(): void {
        this.dragEnabled = false;
    }

    public setDragButtons(buttons: number[]): void {
        this.dragButtons = buttons;
    }

    /**
     * Copy and paste of `Konva.Node._listenDrag()` so can drag layer instead of stage.
     */
    public onDrag(e: Konva.KonvaEventObject<MouseEvent>): void {
        e.evt.preventDefault();

        if (!this.dragEnabled || !this.isDragButton(e.evt) || this.layer.isDragging()) {
            return;
        }

        let hasDraggingChild = false;

        DD._dragElements.forEach((elem) => {
            if (this.layer.isAncestorOf(elem.node)) {
                hasDraggingChild = true;
            }
        });

        if (!hasDraggingChild) {
            this.layer._createDragElement(e);
        }
    }

    private onTouchMove(e: Konva.KonvaEventObject<TouchEvent>): void {
        e.evt.preventDefault();

        const touch1 = e.evt.touches[0];
        const touch2 = e.evt.touches[1];

        if (touch1 && !touch2 && !this.layer.isDragging() && this.dragStopped) {
            this.layer.startDrag();
            this.dragStopped = false;
        }

        if (!(touch1 && touch2)) {
            return;
        }

        if (this.layer.isDragging()) {
            this.dragStopped = true;
            this.layer.stopDrag();
        }

        const p1 = {
          x: touch1.clientX,
          y: touch1.clientY,
        };
        const p2 = {
          x: touch2.clientX,
          y: touch2.clientY,
        };

        if (!this.lastCenter) {
            this.lastCenter = this.getCenter(p1, p2);
            return;
        }

        const newCenter = this.getCenter(p1, p2);
        const dist = this.getDistance(p1, p2);

        if (!this.lastDist) {
            this.lastDist = dist;
        }

        const pointTo = {
            x: (newCenter.x - this.layer.x()) / this.layer.scaleX(),
            y: (newCenter.y - this.layer.y()) / this.layer.scaleX(),
        };
        const scale = this.layer.scaleX() * (dist / this.lastDist);

        this.layer.scaleX(scale);
        this.layer.scaleY(scale);

        const dx = newCenter.x - this.lastCenter.x;
        const dy = newCenter.y - this.lastCenter.y;
        const newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy,
        };

        this.layer.position(newPos);

        this.lastDist = dist;
        this.lastCenter = newCenter;
    }

    private onTouchEnd(): void {
        this.lastDist = 0;
        this.lastCenter = null;
    }

    private getDistance(p1: Vector2d, p2: Vector2d): number {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    private getCenter(p1: Vector2d, p2: Vector2d): Vector2d {
        return {
            x: (p1.x + p2.x) / 2,
            y: (p1.y + p2.y) / 2,
        };
    }

    private getContext(): CanvasRenderingContext2D {
        const ctx: CanvasRenderingContext2D|null = this.htmlCanvas.getContext("2d", { willReadFrequently: true });
        assert(ctx !== null, "Failed to retrieve 2d context");
        return ctx;
    }

    private isDragButton(event: MouseEvent): boolean {
        return event.button === undefined || this.dragButtons.indexOf(event.button) >= 0;
    }
}
