import { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Component, ReactNode } from "react";

interface LastTableRowProps {
    children?: ReactNode;
}

export class LastTableRow extends Component<LastTableRowProps, {}> {
    public render(): ReactNode {
        return (
            <TableRow sx={{
                [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none"
                }
            }}>
                {this.props.children}
            </TableRow>
        );
    }
}
