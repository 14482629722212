import assert from "assert";
import { Component, FormEvent, ReactElement, ReactNode } from "react";
import { ApiClient } from "../../components/clients/api_client";
import { AlertProps } from "../../components/layout/alert";
import { WalletHandler, WalletState } from "../../components/wallet/wallet_handler";
import { recaptcha } from "../../config/recaptcha";
import "./subscribe.css";

declare global {
    var subscribeEmail: (token: string) => void;
    var clearToken: () => void;
    var grecaptchaSafe: (callback: () => void) => void;
}

let subscribe: Subscribe|null = null;

global.subscribeEmail = async (token: string): Promise<void> => {
    Subscribe.token = token;

    const input = Subscribe.emailInput();
    const email = (input.value ?? "").trim();

    subscribe?.notifyMessage("Subscribing, please wait ...");

    try {
        const client = await WalletHandler.getInstance().getWalletClient();
        const signature = await client.signMessage({ message: `Sign this message to apply for beta access (${token.substring(0, 32)})` });
        await ApiClient.getInstance().createSubscription(token, email, client.account.address, signature);
        subscribe?.notifySuccess("Please check your email for verification link");
    } catch {
        subscribe?.notifyError("Something went wrong");
    }
};

global.clearToken = () => Subscribe.token = null;

interface SubscribeProps {
    alertCb?: (alert: AlertProps) => void;
}

interface SubscribeState {
    walletState: WalletState;
    snackbar: {
        children?: ReactElement;
        message?: string;
        autoHideDuration?: number;
    }|null;
    submitted: boolean;
    confirmed: boolean;
    errorMessage: string|null;
    emailInputBorderColour: string;
    subscriptionId: string|null;
}

export class Subscribe extends Component<SubscribeProps, SubscribeState> {
    public static token: string|null = null;
    private recaptchaId: number|null = null;

    state: SubscribeState = {
        walletState: WalletState.LOADING,
        snackbar: null,
        submitted: false,
        confirmed: false,
        errorMessage: null,
        emailInputBorderColour: "#eaeaea",
        subscriptionId: null,
    };

    public static emailInput(): HTMLInputElement {
        const input = document.getElementById("subscribe-email-input") as HTMLInputElement;
        assert(input);
        return input;
    }

    public componentDidMount(): void {
        subscribe = this;

        global.grecaptchaSafe(() => {
            grecaptcha.ready(() => {
                if (this.recaptchaId === null) {
                    this.recaptchaId = grecaptcha.render("subscribe-recaptcha", {
                        "sitekey": recaptcha.SITE_KEY,
                        "callback": global.subscribeEmail,
                        "expired-callback": global.clearToken,
                        "size": "invisible",
                    });
                }
            });
        });

        const subscriptionId = WalletHandler.getInstance().subscribeStateChanged(
            (walletState) => this.setState((state) => ({
                ...state,
                walletState: walletState,
            }))
        );
        this.setState((state) => ({ ...state, subscriptionId }));
    }

    public componentWillUnmount(): void {
        if (subscribe === this) {
            subscribe = null;
        }

        if (this.state.subscriptionId) {
            WalletHandler.getInstance().unsubscribeStateChanged(this.state.subscriptionId);
        }
    }

    public render(): ReactNode {
        return (
            <div id="subscribe-background">
                <div className="container" id="subscribe">
                    <section id="subscribe-row">
                        <div id="subscribe-recaptcha"></div>
                        <form onSubmit={async (e) => this.subscribe(e)}>
                            <p className="rgb-black">BETA IS LIVE!</p>
                            <h4 className="rgb-black">APPLY FOR BETA ACCESS</h4>
                            <input id="subscribe-email-input"
                                   className="text-input"
                                   type="text"
                                   placeholder="Email"
                                   style={{ borderColor: this.state.emailInputBorderColour }}
                            />
                            <input className="rgb-button button" type="submit" value="Sign Up"/>
                        </form>
                    </section>
                </div>
            </div>
        );
    }

    public notifyMessage(message: string): void {
        this.displayAlert(message, "message", 2000);
        this.setState((state) => ({ ...state, emailInputBorderColour: "#eaeaea" }));
    }

    public notifySuccess(message: string): void {
        this.displayAlert(message, "success");
        this.setState((state) => ({ ...state, emailInputBorderColour: "#49be25" }));
    }

    public notifyError(message: string): void {
        this.displayAlert(message, "error");
        this.setState((state) => ({ ...state, emailInputBorderColour: "#be4d25" }));
    }

    private displayAlert(
        message: string,
        severity: "success" | "info" | "warning" | "error" | "message",
        autoHideDuration?: number
    ): void {
        if (!!this.props.alertCb) {
            this.props.alertCb({
                message: message,
                severity: severity,
                autoHideDuration: autoHideDuration,
            });
        }
    }

    private async subscribe(event: FormEvent): Promise<void> {
        event.preventDefault();

        switch (this.state.walletState) {
            case WalletState.NOT_CONNECTED:
                await WalletHandler.getInstance().openConnect();
                return;

            case WalletState.UNSUPPORTED_NETWORK:
                await WalletHandler.getInstance().openChain();
                return;

            case WalletState.LOADING:
            case WalletState.AUTHENTICATING:
                this.notifyMessage("Wallet loading, please wait...");
                return;

            case WalletState.UNAUTHENTICATED:
            default:
                break;
        }

        const input = Subscribe.emailInput();
        const email = (input.value ?? "").trim();

        if (!email) {
            return;
        }

        const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);

        if (!emailRegex.test(email)) {
            this.notifyError("Invalid email");
            return;
        }

        if (Subscribe.token) {
            global.subscribeEmail(Subscribe.token)
            return;
        }

        // Discovered some races with submitting and grecaptcha not being loaded so this is part of a queuing system
        // which runs the subscribe after grecaptcha has successfully loaded
        global.grecaptchaSafe(() => grecaptcha.execute());
    }
}
