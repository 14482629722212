import { Component, ReactNode } from "react";
import "./spinner.css";

interface SpinnerProps {
    colour?: string;
    width: number;
    height: number;
}

export class Spinner extends Component<SpinnerProps, {}> {
    render(): ReactNode {
        return (
            <svg className={`spinner-${this.props.colour ?? "black"}`} viewBox="0 0 50 50" width={this.props.width} height={this.props.height}>
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
        );
    }
}
