import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import { Component, ReactNode } from "react";
import { AccountInfo } from "./account_info";
import { ChainInfo } from "./chain_info";
import { Copyright } from "./copyright";
import { LiveTransactions } from "./live_transactions";
import { NavBar } from "./navbar";

export class Beta extends Component {
    public render(): ReactNode {
        return (
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <NavBar />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            {/* Blockchain */}
                            <ChainInfo />
                            {/* Account Information */}
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: "flex", flexDirection: "column", overflowX: "auto" }}>
                                    <AccountInfo />
                                </Paper>
                            </Grid>
                            {/* Live Transactions */}
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: "flex", flexDirection: "column", overflowX: "auto" }}>
                                    <LiveTransactions />
                                </Paper>
                            </Grid>
                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
        );
    }
}
