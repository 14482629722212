import Typography from "@mui/material/Typography";
import { Component, ReactNode } from "react";

interface TitleProps {
    children?: ReactNode;
}

export class Title extends Component<TitleProps, {}> {
    public render(): ReactNode {
        return (
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                {this.props.children}
            </Typography>
        );
    }
}
