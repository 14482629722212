import assert from "assert";
import { Address } from "viem";
import { WalletHandler } from "../wallet/wallet_handler";
import { ApiClient } from "./api_client";
import { serialiseColours, serialiseCoords } from "./codec";
import { ContractInfo } from "./types";
import { CanvasCache } from "../cache/canvas_cache";

export class LiskClient {
    private static instance: LiskClient|null = null;
    private contractInfo: ContractInfo|null = null;

    private constructor() {}

    public static getInstance(): LiskClient {
        if (LiskClient.instance === null) {
            LiskClient.instance = new LiskClient();
        }

        assert(LiskClient.instance !== null);
        return LiskClient.instance;
    }

    public async tokenAddress(): Promise<Address> {
        const contractInfo = await this.loadContractInfo();
        return contractInfo.token.address as Address;
    }

    public async tokenAbi(): Promise<any> {
        const contractInfo = await this.loadContractInfo();
        return contractInfo.token.abi as Address;
    }

    public async draw(canvasId: number, coords: number[], colours: number[]): Promise<void> {
        const client = await WalletHandler.getInstance().getWalletClient();
        const canvas = await CanvasCache.getInstance().getCanvas(canvasId);

        if (canvas === null) {
            throw new Error(`Failed to find canvas ${canvasId}`);
        }

        // TODO: not calculating gas tax yet
        let fees = BigInt(0);

        if (canvas.drawSettings.costPerPixel.currency === 0 &&
            BigInt(canvas.drawSettings.costPerPixel.value) > BigInt(0)
        ) {
            fees += BigInt(canvas.drawSettings.costPerPixel.value) * BigInt(coords.length);
        }

        if (canvas.lotterySettings.isLottery &&
            canvas.lotterySettings.costPerPixel.currency === 0 &&
            BigInt(canvas.lotterySettings.costPerPixel.value) > BigInt(0)
        ) {
            fees += BigInt(canvas.lotterySettings.costPerPixel.value) * BigInt(coords.length);
        }

        // TODO: search viem examples on how to estimate gas for writing to contract
        // TODO: could be something in wagmi as well
        // TODO: or we push gas fees from api, probs easier and better performance
        await client.writeContract({
            address: await this.tokenAddress(),
            abi: await this.tokenAbi(),
            functionName: "draw",
            args: [canvasId, "0x" + serialiseCoords(coords).toString("hex"), "0x" + serialiseColours(colours).toString("hex")],
            value: fees,
        });
    }

    private async loadContractInfo(): Promise<ContractInfo> {
        if (this.contractInfo === null) {
            this.contractInfo = await ApiClient.getInstance().queryContractInfo();
        }

        return this.contractInfo;
    }
}
