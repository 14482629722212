import { Component, Fragment, ReactNode } from "react";
import { Link } from "react-scroll";
import background from "../../assets/images/header-background.svg";
import headerLarge from "../../assets/images/header-image.svg";
import headerSmall from "../../assets/images/header-image-mobile.png";
import "./header.css";

export class Header extends Component {
    componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.4 });
        const hiddenElements = document.querySelectorAll("#header-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));
    }

    render(): ReactNode {
        return (
            <Fragment>
                <img id="header-row-background" src={background} alt=""/>
                <div className="container">
                    <section id="header-row">
                        <div id="header-description-col" className="hidden">
                            <h3 className="rgb-blue">World First NFT Collaboration Platform</h3>
                            <h1 className="rgb-black">Coming Soon</h1>
                            <p className="rgb-black">Join us as we create history by capturing internet culture in NFT format using the RGB platform powered by Lisk.</p>
                            <Link
                                className="rgb-button"
                                to="learn"
                                smooth={true}
                                offset={-70}
                                duration= {500}
                            >Learn More</Link>
                        </div>
                        <div id="header-image-col-lg" className="hidden">
                            <img src={headerLarge} alt=""/>
                        </div>
                        <div id="header-image-col-sm" className="hidden">
                            <img src={headerSmall} alt=""/>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }
}
