import { Component, ReactNode } from "react";
import earn from "../../assets/images/earn-image.svg";
import "./earn.css";

export class Earn extends Component {
    componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#earn-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));
    }

    render(): ReactNode {
        return (
            <div className="container">
                <section id="earn-row">
                    <div id="earn-description-col" className="hidden">
                        <h2 className="rgb-pink">Earn</h2>
                        <p>Each contribution guarantees you a percentage of current and future sales of the completed NFT. Also, contributing gives you a chance to win as each contribution is pooled together and distributed back to the lucky winners.</p>
                    </div>
                    <div id="earn-image-col" className="hidden">
                        <img src={earn} alt=""/>
                    </div>
                </section>
            </div>
        );
    }
}
