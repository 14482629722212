import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Component, ReactNode } from "react";
import { WalletHandler, WalletState } from "./wallet_handler";

export class WalletConnector extends Component {
    public render(): ReactNode {
        return (
            <ConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                accountModalOpen,
                chainModalOpen,
                connectModalOpen,
                mounted,
            }) => {
                // TODO: review authentication
                const ready = mounted && authenticationStatus !== "loading";
                const connected = ready && account && chain && (!authenticationStatus || authenticationStatus === "authenticated");
                const state = this.calculateState(ready, connected ?? false, chain?.unsupported ?? true);

                if (WalletHandler.getInstance().state() !== state) {
                    WalletHandler.getInstance().setState(state);

                    // Guaranteeing initialisation called at least once after wallet connected
                    if (state === WalletState.PENDING_AUTHENTICATION) {
                        WalletHandler.getInstance().reinit();
                    }
                }

                WalletHandler.getInstance().registerOpenAccountHook(openAccountModal);
                WalletHandler.getInstance().registerOpenChainHook(openChainModal);
                WalletHandler.getInstance().registerOpenConnectHook(openConnectModal);
                WalletHandler.getInstance().setAccountOpen(accountModalOpen);
                WalletHandler.getInstance().setChainOpen(chainModalOpen);
                WalletHandler.getInstance().setConnectOpen(connectModalOpen);
                return <></>;
            }}
            </ConnectButton.Custom>
        );
    }

    private calculateState(ready: boolean, connected: boolean, unsupported: boolean): WalletState {
        if (!ready) {
            return WalletState.LOADING;
        }

        if (!connected) {
            return WalletState.NOT_CONNECTED;
        }

        if (unsupported) {
            return WalletState.UNSUPPORTED_NETWORK;
        }

        return WalletState.PENDING_AUTHENTICATION;
    }
}
