import Konva from "konva";
import { Canvas } from "./canvas";
import { DeferredRender } from "./deferred_render";
import { DrawHandler } from "./draw_handler";
import { Highlightable } from "./highlightable";
import { Coords } from "./types";

export class Palette implements DeferredRender {
    private readonly canvas: Canvas;
    private readonly drawable: DrawHandler;
    private readonly highlightable: Highlightable;
    private readonly layer: Konva.Layer;
    private readonly group: Konva.Group;
    private readonly palette: Map<string, Konva.Rect>;
    private readonly highlightedColour: Konva.Rect;
    private readonly selectedColour: Konva.Rect;

    public constructor(canvas: Canvas, drawable: DrawHandler, highlightable: Highlightable, layer: Konva.Layer) {
        this.canvas = canvas;
        this.drawable = drawable;
        this.highlightable = highlightable;
        this.layer = layer;
        this.group = new Konva.Group({
            width: 320,
            height: 80,
        });
        this.palette = new Map();
        this.highlightedColour = new Konva.Rect({
            stroke: "#ffc844",
            strokeWidth: 2,
            shadowColor: "#000000",
            shadowBlur: 10,
            shadowOffset: { x: 0.1, y: 0.1 },
            shadowOpacity: 0.3,
        });
        this.selectedColour = new Konva.Rect({
            stroke: "#ffc844",
            strokeWidth: 2,
            shadowColor: "#000000",
            shadowBlur: 10,
            shadowOffset: { x: 0.1, y: 0.1 },
            shadowOpacity: 0.3,
        });
    }

    public load(): void {
        // Border is set to width/height of group
        const border = new Konva.Rect({
            width: this.group.width(),
            height: this.group.height(),
            stroke: "#373737",
            strokeWidth: 8,
            cornerRadius: 3,
        });
        this.group.add(border);

        // Palette is dynamically calculated to fit width/height of group
        const colours = this.canvas.getProperties().colourPalette;
        const rows = 2;
        const rowHeight = this.group.height() / 2;
        const columns = colours.length / 2;
        const columnWidth = this.group.width() / columns;

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < columns; col++) {
                const colour = colours[col + (row * columns)].toUpperCase();
                const element = new Konva.Rect({
                    x: col * columnWidth,
                    y: row * rowHeight,
                    width: columnWidth,
                    height: rowHeight,
                    fill: colours[col + (row * columns)],
                });
                element.on("mousemove", () => this.highlight(colour));
                element.on("tap", () => this.onTap(colour));
                this.group.add(element);
                this.palette.set(colour, element);
            }
        }

        // Initialising border for highlighted colour (mouse over events)
        this.highlightedColour.width(columnWidth);
        this.highlightedColour.height(rowHeight);
        this.highlightedColour.hide();
        this.highlightedColour.on("click", () => this.onHighlightClick());
        this.highlightedColour.on("mouseout mouseleave", () => this.unhighlight());
        // Note: adding before so highlighted is rendered underneath selected
        this.group.add(this.highlightedColour);

        // Initialising border for selected colour (used for when pixel is selected on canvas or colour is selected)
        this.selectedColour.width(columnWidth);
        this.selectedColour.height(rowHeight);
        this.selectedColour.hide();
        this.selectedColour.on("click", () => this.onSelectedClick());
        this.selectedColour.on("tap", () => this.deselect());
        this.group.add(this.selectedColour);

        this.layer.add(this.group);

        this.highlightable.registerOnSelectListener((coords: Coords) => this.onSelect(coords));
        this.highlightable.registerOnDeselectListener(() => this.deselect());
        this.highlightable.registerOnHighlightListener(() => this.unhighlight());
        this.drawable.registerOnCommitListener(() => this.deselect());
        this.drawable.registerOnCancelListener(() => this.deselect());
    }

    public unload(): void {
        this.group.remove();
        this.group.removeChildren();
        this.highlightedColour.off("click mouseout mouseleave");
        this.selectedColour.off("click");
    }

    public fitIntoStage(stage: Konva.Stage): void {
        this.group.x(Math.floor((stage.width()) / 2) - (this.group.width() / 2));
        this.group.y(Math.floor(stage.height() - 120));
    }

    public show(): void {
        this.group.show();
    }

    public hide(): void {
        this.deselect();
        this.unhighlight();
        this.group.hide();
    }

    private onTap(colour: string): void {
        this.select(colour);
        this.unhighlight();
        this.drawable.setDrawColour(colour);
    }

    private onHighlightClick(): void {
        const colour = this.highlightedColour.fill();
        this.onTap(colour);
    }

    private onSelectedClick(): void {
        const colour = this.selectedColour.fill();
        this.deselect();
        this.highlight(colour);
    }

    private onSelect(coords: Coords): void {
        if (!this.selectedColour.isVisible()) {
            const colour = this.drawable.getOverlayColour(coords) ?? this.canvas.getColour(coords);
            this.select(colour);
        }
    }

    private select(colour: string): void {
        const element = this.getElement(colour);

        if (element === null ||
            (this.selectedColour.isVisible() &&
             this.selectedColour.x() === element.x() &&
             this.selectedColour.y() === element.y() &&
             this.selectedColour.fill() === colour)
        ) {
            return;
        }

        this.selectedColour.x(element.x());
        this.selectedColour.y(element.y());
        this.selectedColour.fill(colour);
        this.selectedColour.show();
        this.highlightable.updateColour(colour);

        if (this.drawable.freeDrawEnabled() && this.highlightable.getSelectedCoords() === null) {
            this.canvas.disableDrag();
        }
    }

    private deselect(): void {
        if (this.selectedColour.isVisible()) {
            this.selectedColour.hide();
            this.drawable.setDrawColour(null);
            this.highlightable.updateColour(null);
        }

        this.canvas.enableDrag();
    }

    private highlight(colour: string): void {
        const element = this.getElement(colour);

        if (element === null ||
            (this.highlightedColour.isVisible() &&
             this.highlightedColour.x() === element.x() &&
             this.highlightedColour.y() === element.y() &&
             this.highlightedColour.fill() === colour)
        ) {
            return;
        }

        this.highlightedColour.x(element.x());
        this.highlightedColour.y(element.y());
        this.highlightedColour.fill(colour);
        this.highlightedColour.show();
        this.highlightable.updateColour(colour);
    }

    private unhighlight(): void {
        if (this.highlightedColour.isVisible()) {
            this.highlightedColour.hide();
            const colour = this.selectedColour.isVisible() ? this.selectedColour.fill() : null;
            this.highlightable.updateColour(colour);
        }
    }

    private getElement(colour: string): Konva.Rect|null {
        // Colour keys are stored in upper case
        const element = this.palette.get(colour.toUpperCase());

        // Normalising undefined to null
        if (element === undefined || element === null) {
            return null;
        }

        return element;
    }
}
