import { getDefaultConfig, lightTheme } from "@rainbow-me/rainbowkit";
import { optimism } from "wagmi/chains";
import { lisk } from "../../config/lisk";

export const liskChain = {
    ...optimism,
    id: lisk.CHAIN_ID,
    name: lisk.CHAIN_NAME,
    nativeCurrency: { name: lisk.CURRENCY_NAME, symbol: lisk.CURRENCY_SYMBOL, decimals: 18 },
    rpcUrls: {
        default: {
            http: [lisk.NETWORK_URL],
        },
    },
};

export const liskConfig = getDefaultConfig({
    appName: "rgb",
    projectId: lisk.PROJECT_ID,
    chains: [liskChain],
    appUrl: lisk.NETWORK_URL,
});

export const walletTheme = lightTheme({
    accentColor: "#7273f1",
});
