import { Component, ReactNode } from "react";
import Typography from "@mui/material/Typography";

export class Copyright extends Component<any, {}> {
    public render(): ReactNode {
        return (
            <Typography variant="body2" color="text.secondary" align="center" {...this.props}>
                {"Copyright \u00A9 " + new Date().getFullYear() + " Tomilion Pty Ltd. All Rights Reserved"}
            </Typography>
        );
    }
}
