import { Component, ReactNode } from "react";
import collaborate from "../../assets/images/collaborate-image.svg";
import "./collaborate.css";

export class Collaborate extends Component {
    componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#collaborate-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));
    }

    render(): ReactNode {
        return (
            <div id="collaborate-row-background">
                <div className="container">
                    <section id="collaborate-row">
                        <div id="collaborate-image-col" className="hidden">
                            <img src={collaborate} alt=""/>
                        </div>
                        <div id="collaborate-description-col" className="hidden">
                            <h2 className="rgb-orange">Collaborate</h2>
                            <p>Work with others and create something bigger than yourself. RGB provides a number of tools to assist in coordinating these masterpieces of monumental size. Communities and individuals alike can compete for that final position on the completed canvas.</p>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
