import { getAccount, getPublicClient, getWalletClient } from "@wagmi/core"
import { GetPublicClientReturnType } from "@wagmi/core/src/actions/getPublicClient";
import { GetWalletClientReturnType } from "@wagmi/core/src/actions/getWalletClient";
import assert from "assert";
import { v4 as uuid } from "uuid";
import { formatEther, Hex } from "viem";
import { ApiClient } from "../clients/api_client";
import { LiskClient } from "../clients/lisk_client";
import { ACCESS_TOKEN_KEY } from "../util/common";
import { ResolvablePromise, retry } from "../util/helpers";
import { liskConfig } from "./config";

type HookCallback = () => void;

export enum WalletState {
    LOADING = 0,
    NOT_CONNECTED = 1,
    UNSUPPORTED_NETWORK = 2,
    PENDING_AUTHENTICATION = 3,
    AUTHENTICATING = 4,
    UNAUTHENTICATED = 5,
    AUTHENTICATED = 6,
    EMAIL_NOT_VERIFIED = 7,
}

export interface AccountBalance {
    address: string;
    balance: string;
}

export class WalletHandler {
    private static instance?: WalletHandler;
    private openAccountHook: ResolvablePromise<HookCallback> = new ResolvablePromise<HookCallback>();
    private openChainHook: ResolvablePromise<HookCallback> = new ResolvablePromise<HookCallback>();
    private openConnectHook: ResolvablePromise<HookCallback> = new ResolvablePromise<HookCallback>();
    private walletReady: ResolvablePromise<void> = new ResolvablePromise<void>();
    private initialised: boolean = false;
    private _authenticating: ResolvablePromise<void>|null = null;
    private _initialising: ResolvablePromise<void>|null = null;
    private _isAuthenticated: ResolvablePromise<boolean> = new ResolvablePromise<boolean>();
    private _isAccountOpen: boolean = false;
    private _isChainOpen: boolean = false;
    private _isConnectOpen: boolean = false;
    private _state = WalletState.LOADING;
    private stateSubscriptions: Record<string, (state: WalletState) => void> = {};
    private accountOpenSubscriptions: Record<string, (isOpen: boolean) => void> = {};
    private chainOpenSubscriptions: Record<string, (isOpen: boolean) => void> = {};
    private connectOpenSubscriptions: Record<string, (isOpen: boolean) => void> = {};
    private isAuthenticatedSubscriptions: Record<string, (isAuthenticated: boolean) => void> = {};

    public static getInstance(): WalletHandler {
        if (WalletHandler.instance === undefined) {
            WalletHandler.instance = new WalletHandler();
        }

        assert(WalletHandler.instance !== undefined);
        return WalletHandler.instance;
    }

    public async reinit(): Promise<void> {
        // Allowing for reinitialisation after wallet reconnects
        this.initialised = false;
        await this.init();
    }

    public async init(): Promise<void> {
        // Should only be initialised once after wallet connected
        if (this.initialised) {
            return;
        }

        // Merging parallel calls to init
        if (this._initialising !== null && !this._initialising.isResolved()) {
            return this._initialising.promise();
        }

        // TODO: design cleaner way to merge parallel calls while also binding listener to the internal promise of ResolvablePromise
        this._initialising = new ResolvablePromise<void>();
        this.setState(WalletState.PENDING_AUTHENTICATION);

        try {
            const accessToken = this.loadToken();

            if (!accessToken) {
                this.clearToken();
                return this._initialising.promise();
            }

            const client = await this.getWalletClient();
            const account = await ApiClient.getInstance().queryAccount(accessToken);

            if (!account || account.address.toLowerCase() !== client.account.address.toLowerCase()) {
                this.clearToken();
                return this._initialising.promise();
            }

            this.setToken(accessToken);
            this.setState(WalletState.AUTHENTICATED);
            return this._initialising.promise();
        } catch {
            this.clearToken();
            return this._initialising.promise();
        } finally {
            this.initialised = true;
            this._initialising.resolve();
        }
    }

    public async authenticate(): Promise<void> {
        await this.init();

        // Merging parallel calls to authenticate
        if (this._authenticating !== null && !this._authenticating.isResolved()) {
            return this._authenticating.promise();
        }

        // TODO: design cleaner way to merge parallel calls while also binding listener to the internal promise of ResolvablePromise
        this._authenticating = new ResolvablePromise<void>();
        this.setState(WalletState.AUTHENTICATING);
        ApiClient.getInstance().refreshAccessToken();

        try {
            const client = await this.getWalletClient();
            const subscribed = await ApiClient.getInstance().querySubscriptionVerified(client.account.address);

            if (!subscribed) {
                this.clearToken();
                this.setState(subscribed === null ? WalletState.UNAUTHENTICATED : WalletState.EMAIL_NOT_VERIFIED);
                return this._authenticating.promise();
            }

            const signature = await client.signMessage({ message: "Sign this message to generate an access token to the beta" });
            const accessToken = await ApiClient.getInstance().authenticate(client.account.address, signature);

            if (!accessToken) {
                this.clearToken();
                this.setState(WalletState.UNAUTHENTICATED);
                return this._authenticating.promise();
            }

            this.setToken(accessToken);
            this.setState(WalletState.AUTHENTICATED);
            return this._authenticating.promise();
        } catch (error) {
            this.clearToken();
            this.setState(WalletState.PENDING_AUTHENTICATION);
            this._authenticating.reject(error);
            return this._authenticating.promise();
        } finally {
            if (!this._authenticating.isResolved()) {
                this._authenticating.resolve();
            }
        }
    }

    public async signMessage(message: string): Promise<Hex> {
        const client = await this.getWalletClient();
        return client.signMessage({ message })
    }

    public async getWalletClient(): Promise<GetWalletClientReturnType> {
        await this.walletReady.promise();
        return retry(async () => getWalletClient(liskConfig), 250, 20);
    }

    public getPublicClient(): GetPublicClientReturnType {
        return getPublicClient(liskConfig);
    }

    // Inferring return type because too lazy to find narrowed generic types
    public getAccount() {
        return getAccount(liskConfig);
    }

    public async getAccountBalances(): Promise<AccountBalance[]> {
        const client = await this.getWalletClient();
        const addresses = await client.getAddresses();
        const balances = addresses.map(async (address) => {
            return {
                address: address,
                balance: await this.queryBalance(address),
            }
        });
        return Promise.all(balances);
    }

    public async openAccount(): Promise<void> {
        (await this.openAccountHook.promise())();
    }

    public async openChain(): Promise<void> {
        (await this.openChainHook.promise())();
    }

    public async openConnect(): Promise<void> {
        (await this.openConnectHook.promise())();
    }

    public isAuthenticated(): Promise<boolean> {
        return this._isAuthenticated.promise();
    }

    public isAccountOpen(): boolean {
        return this._isAccountOpen;
    }

    public isChainOpen(): boolean {
        return this._isChainOpen;
    }

    public isConnectOpen(): boolean {
        return this._isConnectOpen;
    }

    public state(): WalletState {
        return this._state;
    }

    public subscribeAccountOpen(listener: (open: boolean) => void): string {
        const subscriptionId = uuid();
        this.accountOpenSubscriptions[subscriptionId] = listener;
        // Initialise listener to current state
        setTimeout(() => listener(this.isAccountOpen()), 0);
        return subscriptionId;
    }

    public unsubscribeAccountOpen(subscriptionId: string): void {
        delete this.accountOpenSubscriptions[subscriptionId];
    }

    public subscribeChainOpen(listener: (open: boolean) => void): string {
        const subscriptionId = uuid();
        this.chainOpenSubscriptions[subscriptionId] = listener;
        // Initialise listener to current state
        setTimeout(() => listener(this.isChainOpen()), 0);
        return subscriptionId;
    }

    public unsubscribeChainOpen(subscriptionId: string): void {
        delete this.chainOpenSubscriptions[subscriptionId];
    }

    public subscribeConnectOpen(listener: (open: boolean) => void): string {
        const subscriptionId = uuid();
        this.connectOpenSubscriptions[subscriptionId] = listener;
        // Initialise listener to current state
        setTimeout(() => listener(this.isConnectOpen()), 0);
        return subscriptionId;
    }

    public unsubscribeConnectOpen(subscriptionId: string): void {
        delete this.connectOpenSubscriptions[subscriptionId];
    }

    public subscribeStateChanged(listener: (state: WalletState) => void): string {
        const subscriptionId = uuid();
        this.stateSubscriptions[subscriptionId] = listener;
        // Initialise listener to current state
        setTimeout(() => listener(this.state()), 0);
        return subscriptionId;
    }

    public unsubscribeStateChanged(subscriptionId: string): void {
        delete this.stateSubscriptions[subscriptionId];
    }

    public subscribeIsAuthenticated(listener: (isAuthenticated: boolean) => void): string {
        const subscriptionId = uuid();
        this.isAuthenticatedSubscriptions[subscriptionId] = listener;
        // Initialise listener to current state
        setTimeout(async () => listener(await this.isAuthenticated()), 0);
        return subscriptionId;
    }

    public unsubscribeIsAuthenticated(subscriptionId: string): void {
        delete this.isAuthenticatedSubscriptions[subscriptionId];
    }

    public registerOpenAccountHook(hook: HookCallback): void {
        if (this.openAccountHook.isResolved()) {
            this.openAccountHook = new ResolvablePromise<HookCallback>();
        }

        this.openAccountHook.resolve(hook);
    }

    public registerOpenChainHook(hook: HookCallback): void {
        if (this.openChainHook.isResolved()) {
            this.openChainHook = new ResolvablePromise<HookCallback>();
        }

        this.openChainHook.resolve(hook);
    }

    public registerOpenConnectHook(hook: HookCallback): void {
        if (this.openConnectHook.isResolved()) {
            this.openConnectHook = new ResolvablePromise<HookCallback>();
        }

        this.openConnectHook.resolve(hook);
    }

    public setAccountOpen(accountOpen: boolean): void {
        if (this._isAccountOpen === accountOpen) {
            return;
        }

        this._isAccountOpen = accountOpen;

        // Notify listeners of state change
        setTimeout(() => {
            for (const subscriptionId in this.accountOpenSubscriptions) {
                this.accountOpenSubscriptions[subscriptionId](this.isAccountOpen());
            }
        }, 0);
    }

    public setChainOpen(chainOpen: boolean): void {
        if (this._isChainOpen === chainOpen) {
            return;
        }

        this._isChainOpen = chainOpen;

        // Notify listeners of state change
        setTimeout(() => {
            for (const subscriptionId in this.chainOpenSubscriptions) {
                this.chainOpenSubscriptions[subscriptionId](this.isChainOpen());
            }
        }, 0);
    }

    public setConnectOpen(connectOpen: boolean): void {
        if (this._isConnectOpen === connectOpen) {
            return;
        }

        this._isConnectOpen = connectOpen;

        // Notify listeners of state change
        setTimeout(() => {
            for (const subscriptionId in this.connectOpenSubscriptions) {
                this.connectOpenSubscriptions[subscriptionId](this.isConnectOpen());
            }
        }, 0);
    }

    public setState(state: WalletState): void {
        if (this._state === state) {
            return;
        }

        this._state = state;

        switch (this.state()) {
            case WalletState.LOADING:
            case WalletState.NOT_CONNECTED:
            case WalletState.UNSUPPORTED_NETWORK:
                this.walletDisconnected();
                break;

            case WalletState.PENDING_AUTHENTICATION:
                this.walletConnected();
                break;

            case WalletState.UNAUTHENTICATED:
            case WalletState.AUTHENTICATED:
            case WalletState.EMAIL_NOT_VERIFIED:
            case WalletState.AUTHENTICATING:
            default:
                break;
        }

        // Notify listeners of state change
        setTimeout(() => {
            for (const subscriptionId in this.stateSubscriptions) {
                this.stateSubscriptions[subscriptionId](this.state());
            }
        }, 0);
    }

    private async queryBalance(address: string): Promise<string> {
        const contractClient = this.getPublicClient();
        assert(contractClient);
        const balance = await contractClient.readContract({
            address: (await LiskClient.getInstance().tokenAddress()),
            abi: await LiskClient.getInstance().tokenAbi(),
            functionName: "balanceOf",
            args: [address],
        });

        if (typeof balance === "bigint") {
            return formatEther(balance);
        }

        return "?";
    }

    private loadToken(): string|null {
        const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
        const accessTokenParam = searchParams.get(ACCESS_TOKEN_KEY);

        // Must be non-null and non-empty string
        if (accessTokenParam) {
            return accessTokenParam;
        }

        const accessTokenStorage = localStorage.getItem(ACCESS_TOKEN_KEY);

        // Must be non-null and non-empty string
        if (accessTokenStorage) {
            return accessTokenStorage;
        }

        return null;
    }

    private setToken(accessToken: string): void {
        ApiClient.getInstance().setAccessToken(accessToken);
        localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
        this.resolveIsAuthenticated(true);
    }

    private clearToken(): void {
        ApiClient.getInstance().clearAccessToken();
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        this.resolveIsAuthenticated(false);
    }

    private walletDisconnected(): void {
        if (this.walletReady.isResolved()) {
            this.walletReady = new ResolvablePromise<void>();
        }
    }

    private walletConnected(): void {
        if (!this.walletReady.isResolved()) {
            this.walletReady.resolve();
        }
    }

    private resolveIsAuthenticated(isAuthenticated: boolean) {
        if (this._isAuthenticated.isResolved()) {
            this._isAuthenticated = new ResolvablePromise<boolean>();
        }

        this._isAuthenticated.resolve(isAuthenticated);

        // Notify listeners of is authenticated change
        setTimeout(() => {
            for (const subscriptionId in this.isAuthenticatedSubscriptions) {
                this.isAuthenticatedSubscriptions[subscriptionId](isAuthenticated);
            }
        }, 0);
    }
}
