import { Component, ReactNode } from "react";
import nftmarketplace from "../../assets/images/nft-marketplace-image.svg";
import "./nft_marketplace.css";

export class NftMarketplace extends Component {
    componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#nft-marketplace-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));
    }

    render(): ReactNode {
        return (
            <div id="nft-marketplace-row-background">
                <div className="container">
                    <section id="nft-marketplace-row">
                        <div id="nft-marketplace-image-col" className="hidden">
                            <img src={nftmarketplace} alt=""/>
                        </div>
                        <div id="nft-marketplace-description-col" className="hidden">
                            <h2 className="rgb-white">NFT Marketplace</h2>
                            <p className="rgb-light-grey">All completed NFTs will be available for auction via the NFT marketplace. Stay tuned for more details ...</p>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
