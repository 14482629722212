import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Component, Fragment, ReactNode } from "react";
import emailVerifiedFailed from "../../assets/images/email-verified-failed.svg";
import emailVerifiedSuccess from "../../assets/images/email-verified-success.svg";
import { ApiClient } from "../../components/clients/api_client";
import { ACCESS_TOKEN_KEY, VERIFICATION_TOKEN_KEY } from "../../components/util/common";

interface VerifyState {
    success: boolean;
    redirect: boolean;
    url: string;
}

export class Verify extends Component<any, VerifyState> {
    state: VerifyState = {
        success: true,
        redirect: false,
        url: "/",
    };

    public async componentDidMount(): Promise<void> {
        const searchParams: URLSearchParams = new URLSearchParams(window.location.search);

        if (!searchParams.has(VERIFICATION_TOKEN_KEY)) {
            this.verificationFailed();
            return;
        }

        const verificationToken = searchParams.get(VERIFICATION_TOKEN_KEY);

        if (!verificationToken) {
            this.verificationFailed();
            return;
        }

        const accessToken = await ApiClient.getInstance().verifySubscription(verificationToken);

        if (!accessToken) {
            this.verificationFailed();
            return;
        }

        this.setState((state) => ({ ...state, url: `/beta?${ACCESS_TOKEN_KEY}=${accessToken}` }));
        setTimeout(() => this.setState((state) => ({ ...state, redirect: true })), 2500);
    }

    public render(): ReactNode {
        if (this.state.redirect) {
            // Avoiding react redirect so that wallet state refreshes for authentication
            window.location.replace(this.state.url);
        }

        return (
            <Box sx={{ width: "100vw", height: "100vh", alignContent: "center", backgroundColor: "#7273f1" }}>
                <Container maxWidth="sm">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 8,
                                textAlign: "center",
                                boxShadow: "0px 0px 9px 2px rgba(0,0,0,0.5); -webkit-box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.5); -moz-box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.5);",
                            }}>
                                {this.state.success ?
                                    (<Fragment>
                                        <img src={emailVerifiedSuccess} alt="" width={200}/>
                                        <h3>Success!</h3>
                                        <p>Your email was verified. You may now access the beta.</p>
                                        <p>&nbsp;</p>
                                        <small>Redirecting automatically, <a href={this.state.url}>click here</a> to redirect now</small>
                                    </Fragment>) :
                                    (<Fragment>
                                        <img src={emailVerifiedFailed} alt="" width={200}/>
                                        <h3>Error!</h3>
                                        <p>Oops! Something went wrong</p>
                                        <p>&nbsp;</p>
                                        <small>Redirecting automatically, <a href={this.state.url}>click here</a> to redirect now</small>
                                    </Fragment>)
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        );
    }

    private verificationFailed(): void {
        this.setState((state) => ({ ...state, success: false }));
        setTimeout(() => this.setState((state) => ({ ...state, redirect: true })), 2500);
    }
}
