import { Component, ReactNode } from "react";
import create from "../../assets/images/create-icon.svg";
import collaborate from "../../assets/images/collaborate-icon.svg";
import earn from "../../assets/images/earn-icon.svg";
import "./create_collaborate_earn.css";

export class CreateCollaborateEarn extends Component {
    componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.7 });
        const hiddenElements = document.querySelectorAll("#create-collaborate-earn-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));
    }

    render(): ReactNode {
        return (
            <div className="container">
                <section id="create-collaborate-earn-row">
                    <div id="create-icon-col" className="hidden">
                        <img src={create} alt=""/>
                        <h3 className="rgb-green">Create</h3>
                    </div>
                    <div id="collaborate-icon-col" className="hidden">
                        <img src={collaborate} alt=""/>
                        <h3 className="rgb-orange">Collaborate</h3>
                    </div>
                    <div id="earn-icon-col" className="hidden">
                        <img src={earn} alt=""/>
                        <h3 className="rgb-pink">Earn</h3>
                    </div>
                </section>
            </div>
        );
    }
}
