import Konva from "konva";
import { LiskClient } from "../clients/lisk_client";
import { Canvas } from "./canvas";
import { Confirmation } from "./confirmation";
import { DeferredRender } from "./deferred_render";
import { Highlightable } from "./highlightable";
import { Coords } from "./types";

export class DrawHandler implements DeferredRender {
    private readonly canvas: Canvas;
    private readonly confirmation: Confirmation;
    private readonly highlightable: Highlightable;
    private readonly layer: Konva.Layer;
    private readonly group: Konva.Group;
    private freeDraw: boolean = false;
    private mouseDown: boolean = false;
    private pixelChangeHistory: Konva.Rect[] = [];
    private pixelChangeByCoords: Record<string, Konva.Rect[]> = {};
    private onCommitListeners: (() => void)[] = [];
    private onCancelListeners: (() => void)[] = [];
    private onErrorListeners: ((message: string) => void)[] = [];
    private drawColour: string|null = null;
    private selectedCoords: Coords|null = null;

    public constructor(
        canvas: Canvas,
        confirmation: Confirmation,
        highlightable: Highlightable,
        layer: Konva.Layer
    ) {
        this.canvas = canvas;
        this.confirmation = confirmation;
        this.highlightable = highlightable;
        this.layer = layer;
        this.group = new Konva.Group();
    }

    public load(): void {
        this.layer.add(this.group);
        this.layer.on("mousedown", (e) => this.onMouseDown(e));
        this.layer.on("mouseup", (e) => this.onMouseUp(e));
        this.layer.on("touchstart", () => this.onTouchStart());
        this.layer.on("touchend", () => this.onTouchEnd());
        this.confirmation.registerOnAcceptListener(() => this.onAccept());
        this.confirmation.registerOnCancelListener(() => this.onCancel());
        this.highlightable.registerOnSelectListener((coords: Coords) => this.onSelect(coords));
        this.highlightable.registerOnDeselectListener(() => this.onDeselect());
        this.highlightable.registerOnHighlightListener((coords: Coords) => this.onHighlight(coords));
        this.highlightable.registerOnClickListener((coords: Coords) => this.onClick(coords));
    }

    public unload(): void {
        this.group.remove();
        this.layer.off("mousedown mouseup touchstart touchend");
        this.onCommitListeners = [];
        this.onCancelListeners = [];
    }

    public fitIntoStage(stage: Konva.Stage) {
        // Do nothing
    }

    public registerOnCommitListener(callback: () => void): void {
        this.onCommitListeners.push(callback);
    }

    public registerOnCancelListener(callback: () => void): void {
        this.onCancelListeners.push(callback);
    }

    public registerOnErrorListener(callback: (message: string) => void): void {
        this.onErrorListeners.push(callback);
    }

    public enableFreeDraw(): void {
        this.freeDraw = true;
    }

    public disableFreeDraw(): void {
        this.freeDraw = false;
    }

    public freeDrawEnabled(): boolean {
        return this.freeDraw;
    }

    public setDrawColour(colour: string|null): void {
        if (colour !== null && this.selectedCoords !== null) {
            this.overlayCanvas(this.selectedCoords.x, this.selectedCoords.y, colour);
        }

        this.drawColour = colour;
        this.highlightable.updateCanSelect(colour === null);
    }

    public getOverlayColour(coords: Coords): string|null {
        const serialised = this.serialiseCoords(coords.x, coords.y);

        if (!(serialised in this.pixelChangeByCoords) || this.pixelChangeByCoords[serialised].length === 0) {
            return null;
        }

        return this.pixelChangeByCoords[serialised][this.pixelChangeByCoords[serialised].length - 1].fill().toLowerCase();
    }

    public undo(): void {
        const lastChange = this.pixelChangeHistory.pop();

        if (lastChange !== undefined) {
            const coords = this.serialiseCoords(lastChange.x(), lastChange.y());
            const mapped = this.pixelChangeByCoords[coords];

            if (mapped.length !== 0 && mapped[mapped.length - 1] === lastChange) {
                mapped.pop();
            }

            if (mapped.length === 0) {
                delete this.pixelChangeByCoords[coords];
            }

            lastChange.destroy();
        }

        if (this.pixelChangeHistory.length === 0) {
            this.confirmation.hide();
        }
    }

    private onMouseDown(e: Konva.KonvaEventObject<MouseEvent>): void {
        if (!this.isButton(e.evt, 0)) {
            return;
        }

        this.onTouchStart();
    }

    private onMouseUp(e: Konva.KonvaEventObject<MouseEvent>): void {
        if (!this.isButton(e.evt, 0)) {
            return;
        }

        this.onTouchEnd();
    }

    private onTouchStart(): void {
        this.mouseDown = true;

        if (this.drawColour !== null && !this.highlightable.canHighlight()) {
            this.onErrorListeners.forEach((listener) => listener("Zoom in further to draw"));
            return;
        }

        const coords = this.highlightable.getHighlightedCoords();

        if (coords !== null) {
            this.onHighlight(coords);
        }
    }

    private onTouchEnd(): void {
        this.mouseDown = false;
    }

    private async onAccept(): Promise<void> {
        const properties = this.canvas.getProperties();
        const xCoords: number[] = [];
        const yCoords: number[] = [];
        const colours: string[] = [];
        const serialisedCoords: number[] = [];
        const serialisedColours: number[] = [];

        for (const key in this.pixelChangeByCoords) {
            const change = this.pixelChangeByCoords[key].pop();

            if (change === undefined) {
                continue;
            }

            const x = change.x();
            const y = change.y();
            const colour = change.fill().toLowerCase();
            xCoords.push(x);
            yCoords.push(y);
            colours.push(colour);
            serialisedCoords.push((y * properties.width) + x);
            serialisedColours.push(properties.colourPalette.findIndex((palette) => palette.toLowerCase() === colour));
        }

        this.canvas.updateCanvas(xCoords, yCoords, colours);
        await LiskClient.getInstance().draw(properties.canvasId, serialisedCoords, serialisedColours);

        for (const pixel of this.pixelChangeHistory) {
            pixel.destroy();
        }

        this.pixelChangeHistory = [];
        this.pixelChangeByCoords = {};
        this.confirmation.hide();
        this.onCommitListeners.forEach((listener) => listener());
    }

    private onCancel(): void {
        for (const change of this.pixelChangeHistory) {
            change.destroy();
        }

        this.pixelChangeHistory = [];
        this.pixelChangeByCoords = {};
        this.confirmation.hide();
        this.onCancelListeners.forEach((listener) => listener());
    }

    private onSelect(coords: Coords): void {
        if (this.drawColour !== null) {
            this.overlayCanvas(coords.x, coords.y, this.drawColour);
        }

        this.selectedCoords = coords;
    }

    private onDeselect(): void {
        this.selectedCoords = null;
    }

    private onHighlight(coords: Coords): void {
        if (this.freeDraw && this.mouseDown && this.drawColour !== null) {
            this.overlayCanvas(coords.x, coords.y, this.drawColour);
        }
    }

    private onClick(coords: Coords): void {
        if (this.drawColour !== null && this.highlightable.canHighlight()) {
            this.overlayCanvas(coords.x, coords.y, this.drawColour);
        }
    }

    private overlayCanvas(x: number, y: number, colour: string): void {
        const coords = this.serialiseCoords(x, y);

        if (!(coords in this.pixelChangeByCoords)) {
            this.pixelChangeByCoords[coords] = [];
        }

        if (this.pixelChangeByCoords[coords].length !== 0) {
            const current = this.pixelChangeByCoords[coords][this.pixelChangeByCoords[coords].length - 1];

            // Discarding if already set to given colour
            if (current.fill() === colour) {
                return;
            }
        }

        const change = new Konva.Rect({
            x: x,
            y: y,
            width: 1,
            height: 1,
            fill: colour,
        });
        this.group.add(change);
        this.pixelChangeHistory.push(change);
        this.pixelChangeByCoords[coords].push(change);
        this.confirmation.show();
    }

    private isButton(event: MouseEvent, ...button: number[]): boolean {
        return event.button === undefined || button.indexOf(event.button) >= 0;
    }

    private serialiseCoords(x: number, y: number): string {
        return `${x}-${y}`;
    }
}
