export interface DecodeProperties {
    width: number;
    height: number;
    colourPalette: string[];
}

export interface DecodeRequest {
    id: string;
    view: string;
    properties: DecodeProperties;
}

export interface DecodeResponse {
    id: string;
    view: Uint8ClampedArray;
}

const worker = () => {
    /* eslint-disable no-restricted-globals */
    self.onmessage = (e: MessageEvent<DecodeRequest>) => {
        const { id, view, properties } = e.data;

        const hexToRgba: Record<string, number[]> = {};
        const result = new Uint8ClampedArray(properties.width * properties.height * 4);

        // Decoding colour palette into array of RGBA values
        properties.colourPalette.forEach((colour) => {
            const rgba = colour.substring(1).match(/.{1,2}/g)?.map((hex) => parseInt(hex, 16));

            if (!rgba) {
                throw new Error("Failed to decode hex colour " + colour);
            }

            // Colour only contains rgb values - alpha is manually set to 255
            rgba.push(255);
            hexToRgba[colour] = rgba;
        });

        // View is base64 encoded array of 4bit colours
        const decoded = atob(view);

        for (let i = 0; i < decoded.length; i++) {
            // Each 4bit value refers to index of palette in properties
            const value = decoded.charCodeAt(i);

            // Colour offset 0
            const offset1 = (i * 2);
            const rgba1 = hexToRgba[properties.colourPalette[value & 0x0F]];
            result.set(rgba1, offset1 * 4);

            // Colour offset 1
            const offset2 = (i * 2) + 1;
            const rgba2 = hexToRgba[properties.colourPalette[(value >> 4) & 0x0F]];
            result.set(rgba2, offset2 * 4);
        }

        self.postMessage({ id, view: result });
    };
};

export default worker;
