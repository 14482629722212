import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import { Variant } from "@mui/material/styles/createTypography";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";
import { Component, ElementType, ReactNode } from "react";
import { Title } from "./title";

interface InfoProps {
    title: string;
    children?: ReactNode;
    component?: ElementType;
    variant?: Variant;
    sx?: SxProps<Theme>;
}

const itemStyling = {
    p: 2,
    display: "flex",
    flexDirection: "column",
};

export class InfoPanel extends Component<InfoProps, {}> {
    public render(): ReactNode {
        return (
            <Paper sx={itemStyling}>
                <Title>{this.props.title}</Title>
                <Typography component={this.props.component ?? "p"} variant={this.props.variant ?? "h4"} sx={this.props.sx}>
                    {this.props.children}
                </Typography>
            </Paper>
        );
    }
}
