import Konva from "konva";
import { DeferredRender } from "./deferred_render";

export class Confirmation implements DeferredRender {
    private readonly layer: Konva.Layer;
    private readonly group: Konva.Group;
    private readonly tick: Konva.Group;
    private readonly cross: Konva.Group;
    private onAcceptListeners: (() => void)[] = [];
    private onCancelListeners: (() => void)[] = [];

    public constructor(layer: Konva.Layer) {
        this.layer = layer;
        this.group = new Konva.Group({ visible: false });
        this.tick = Confirmation.createTick();
        this.cross = Confirmation.createCross();
    }

    public load(): void {
        this.group.add(this.tick);
        this.group.add(this.cross);
        this.layer.add(this.group);
        this.tick.on("click tap", () => this.onAccept());
        this.cross.on("click tap", () => this.onCancel());
    }

    public unload(): void {
        this.group.remove();
        this.group.removeChildren();
        this.tick.off("click tap");
        this.cross.off("click tap");
    }

    public fitIntoStage(stage: Konva.Stage): void {
        this.group.x(Math.floor((stage.width() / 2) - (this.group.width() / 2)));
        this.group.y(Math.floor(stage.height() - 165));
    }

    public registerOnAcceptListener(callback: () => void): void {
        this.onAcceptListeners.push(callback);
    }

    public registerOnCancelListener(callback: () => void): void {
        this.onCancelListeners.push(callback);
    }

    public show(): void {
        if (!this.group.isVisible()) {
            this.group.show();
        }
    }

    public hide(): void {
        if (this.group.isVisible()) {
            this.group.hide();
        }
    }

    private onAccept(): void {
        this.onAcceptListeners.forEach((listener) => listener());
    }

    private onCancel(): void {
        this.onCancelListeners.forEach((listener) => listener());
    }

    private static createTick(): Konva.Group {
        const group = new Konva.Group();
        const background = new Konva.Circle({
            x: -30,
            y: 0,
            width: 42,
            height: 42,
            fill: "#373737",
            stroke: "#373737",
            strokeEnabled: false,
        });
        const strokeWidth = background.width() * 0.1269;
        const strokeLength1 = background.width() * 0.5373;
        const strokeLength2 = background.width() * 0.3433;
        const stroke1 = new Konva.Rect({
            x: background.x() + (0.2216 * background.width()),
            y: background.y() - (0.2348 * background.width()),
            width: strokeWidth,
            height: strokeLength1,
            fill: "#ffffff",
            rotation: 45,
        });
        const stroke2 = new Konva.Rect({
            x: background.x() - (0.3113 * background.width()),
            y: background.y() - (0.0079 * background.width()),
            width: strokeWidth,
            height: strokeLength2,
            fill: "#ffffff",
            rotation: -45,
        });
        group.add(background);
        group.add(stroke1);
        group.add(stroke2);
        group.on("mouseover", () => {
            background.strokeEnabled(true);
        });
        group.on("mouseout", () => {
            background.strokeEnabled(false);
        });
        return group;
    }

    private static createCross(): Konva.Group {
        const group = new Konva.Group();
        const background = new Konva.Circle({
            x: 30,
            y: 0,
            width: 42,
            height: 42,
            fill: "#373737",
            stroke: "#373737",
            strokeEnabled: false,
        });
        const strokeLength = background.width() * 0.5597;
        const strokeWidth = background.width() * 0.1269;
        const strokeLengthViewWidth = Math.cos((45 * Math.PI) / 180) * (strokeLength / 2);
        const strokeWidthViewWidth = Math.cos((45 * Math.PI) / 180) * (strokeWidth / 2);
        const stroke1 = new Konva.Rect({
            x: background.x() + strokeLengthViewWidth - strokeWidthViewWidth,
            y: background.y() - strokeLengthViewWidth - strokeWidthViewWidth,
            width: strokeWidth,
            height: strokeLength,
            fill: "#ffffff",
            rotation: 45,
        });
        const stroke2 = new Konva.Rect({
            x: background.x() - strokeLengthViewWidth - strokeWidthViewWidth,
            y: background.y() - strokeLengthViewWidth + strokeWidthViewWidth,
            width: strokeWidth,
            height: strokeLength,
            fill: "#ffffff",
            rotation: -45,
        });
        group.add(background);
        group.add(stroke1);
        group.add(stroke2);
        group.on("mouseover", () => {
            background.strokeEnabled(true);
        });
        group.on("mouseout", () => {
            background.strokeEnabled(false);
        });
        return group;
    }
}
