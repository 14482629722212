import { ChangeEvent, Component, Fragment, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import assert from "assert";
import Konva from "konva";
import autoZoomIcon from "../../assets/images/automatic-zoom.svg";
import autoZoomSelectedIcon from "../../assets/images/automatic-zoom-selected.svg";
import colourPaletteIcon from "../../assets/images/colour-palette.svg";
import colourPaletteSelectedIcon from "../../assets/images/colour-palette-selected.svg";
import freeDrawIcon from "../../assets/images/free-draw.svg";
import freeDrawSelectedIcon from "../../assets/images/free-draw-selected.svg";
import gotoCoordinatesIcon from "../../assets/images/goto.svg";
import gotoCoordinatesSelectedIcon from "../../assets/images/goto-selected.svg";
import gridLinesIcon from "../../assets/images/grid-lines.svg";
import gridLinesSelectedIcon from "../../assets/images/grid-lines-selected.svg";
import logo from "../../assets/images/r-logo-white.svg";
import undoIcon from "../../assets/images/undo.svg";
import undoSelectedIcon from "../../assets/images/undo-selected.svg";
import { CanvasCache } from "../../components/cache/canvas_cache";
import { Canvas } from "../../components/canvas/canvas";
import { Confirmation } from "../../components/canvas/confirmation";
import { Highlightable } from "../../components/canvas/highlightable";
import { Zoomies } from "../../components/canvas/zoomies";
import { DrawHandler } from "../../components/canvas/draw_handler";
import { Palette } from "../../components/canvas/palette";
import { ApiClient } from "../../components/clients/api_client";
import { decodePixels, decodeViewRgba } from "../../components/clients/codec";
import { Canvas as CanvasProperties, ViewProperties } from "../../components/clients/types";
import { CanvasState } from "../../components/clients/schemas";
import { LoadingOverlay } from "../../components/loading/loading_overlay";
import { CANVAS_ID_KEY } from "../../components/util/common";
import { DrawableCanvas, DrawState, MenuState } from "./types";
import "./draw.css";

let draw: Draw|null = null;

window.addEventListener("resize", () => draw?.onResize());

export class Draw extends Component<any, DrawState> {
    private static readonly MENU_KEY = "_dm";

    state: DrawState = {
        loading: true,
        redirectHome: false,
        redirectPending: false,
        canvasId: null,
        container: null,
        canvas: null,
        pendingChanges: [],
        menu: {
            freeDraw: false,
            autoZoom: false,
            colourPalette: true,
            undo: false,
            gridLines: false,
            gotoCoordinates: false,
        },
        gotoCoords: { x: null, y: null },
        snackbar: null,
        canvasCompletedId: null,
        viewChangedId: null,
    };

    public async componentDidMount(): Promise<void> {
        draw = this;

        document.body.style.overflow = "hidden";

        const canvasId = this.getCanvasIdParam() ?? await this.getActiveCanvasId();

        if (canvasId === null) {
            await this.redirectNoActive();
            return;
        }

        const canvas = await ApiClient.getInstance().queryCanvas(canvasId);

        if (canvas === null) {
            await this.redirectNoActive();
            return;
        }

        await this.loadCanvas(canvas);
    }

    public async componentWillUnmount(): Promise<void> {
        draw = null;

        document.body.style.overflow = "";

        if (this.state.canvas === null) {
            return;
        }

        this.state.canvas.canvas.unload();
        this.state.canvas.confirmation.unload();
        this.state.canvas.highlightable.unload();
        this.state.canvas.zoomies.unload();
        this.state.canvas.drawHandler.unload();
        this.state.canvas.palette.unload();

        if (this.state.viewChangedId) {
            await ApiClient.getInstance().unsubscribeViewChanged(this.state.viewChangedId);
        }

        if (this.state.canvasCompletedId) {
            await ApiClient.getInstance().unsubscribeCanvasCompleted(this.state.canvasCompletedId);
        }
    }

    public render(): ReactNode {
        if (this.state.redirectHome) {
            return <Navigate to="/" />;
        }

        if (this.state.redirectPending) {
            return <Navigate to={`/pending?${CANVAS_ID_KEY}=${this.state.canvasId}`} />;
        }

        return (
            <Fragment>
                {this.state.snackbar}
                <LoadingOverlay show={this.state.loading} />
                <div id="draw-menu-container">
                    <input id="draw-hamburger-input" type="checkbox"/>
                    <label htmlFor="draw-hamburger-input" id="draw-hamburger-label">
                        <i></i>
                    </label>
                    <nav id="draw-menu">
                        <ul>
                            <Divider sx={{ my: 1 }} style={{ background: "#F2F2FC" }} />
                            <li>
                                <a href="/">
                                    <img src={logo} alt="Home" className="logo"/>
                                    Home
                                </a>
                            </li>
                            <li>
                                <a href="/beta">
                                    <AssessmentOutlinedIcon style={{ color: "#ffffff" }} />
                                    Dashboard
                                </a>
                            </li>
                            <li>
                                <a href="/gallery">
                                    <CollectionsOutlinedIcon style={{ color: "#ffffff" }} />
                                    Gallery
                                </a>
                            </li>
                            <Divider sx={{ my: 1 }} style={{ background: "#F2F2FC" }} />
                            <li onClick={() => this.toggleFreeDraw()}>
                                <button style={{fontWeight: this.getFontWeight(this.state.menu.freeDraw)}}>
                                    <img src={this.getIcon(this.state.menu.freeDraw, freeDrawIcon, freeDrawSelectedIcon)} alt="Free Draw" className="icon"/>
                                    Free Draw
                                </button>
                            </li>
                            <li onClick={() => this.toggleAutoZoom()}>
                                <button style={{fontWeight: this.getFontWeight(this.state.menu.autoZoom)}}>
                                    <img src={this.getIcon(this.state.menu.autoZoom, autoZoomIcon, autoZoomSelectedIcon)} alt="Auto Zoom" className="icon"/>
                                    Auto Zoom
                                </button>
                            </li>
                            <li onClick={() => this.toggleColourPalette()}>
                                <button style={{fontWeight: this.getFontWeight(this.state.menu.colourPalette)}}>
                                    <img src={this.getIcon(this.state.menu.colourPalette, colourPaletteIcon, colourPaletteSelectedIcon)} alt="Colour Palette" className="icon"/>
                                    Colour Palette
                                </button>
                            </li>
                            <li onClick={() => this.toggleGridLines()}>
                                <button style={{fontWeight: this.getFontWeight(this.state.menu.gridLines)}}>
                                    <img src={this.getIcon(this.state.menu.gridLines, gridLinesIcon, gridLinesSelectedIcon)} alt="Grid Lines" className="icon"/>
                                    Grid Lines
                                </button>
                            </li>
                            <li onClick={() => this.toggleUndo()}>
                                <button style={{fontWeight: this.getFontWeight(this.state.menu.undo)}}>
                                    <img src={this.getIcon(this.state.menu.undo, undoIcon, undoSelectedIcon)} alt="Undo" className="icon"/>
                                    Undo
                                </button>
                            </li>
                            <li onClick={() => this.toggleGotoCoordinates()}>
                                <button style={{fontWeight: this.getFontWeight(this.state.menu.gotoCoordinates)}}>
                                    <img src={this.getIcon(this.state.menu.gotoCoordinates, gotoCoordinatesIcon, gotoCoordinatesSelectedIcon)} alt="Goto Coordinates" className="icon"/>
                                    Goto Coordinates
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div id="draw-canvas-parent" onContextMenu={(e) => e.preventDefault()}>
                    <div id="draw-canvas-container"></div>
                </div>
                <Dialog open={this.state.menu.gotoCoordinates} onClose={() => this.handleCancelGoto()}>
                    <DialogContent>
                        <DialogContentText>
                            Enter coordinates
                        </DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    autoFocus
                                    id="xCoord"
                                    label="x"
                                    type="number"
                                    variant="standard"
                                    onChange={(e) => this.updateGotoX(e)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="yCoord"
                                    label="y"
                                    type="number"
                                    variant="standard"
                                    onChange={(e) => this.updateGotoY(e)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleOkGoto()}>Ok</Button>
                        <Button onClick={() => this.handleCancelGoto()}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }

    public onResize(): void {
        if (this.state.container === null || this.state.canvas === null) {
            return;
        }

        this.state.canvas.stage.width(this.state.container.offsetWidth);
        this.state.canvas.stage.height(this.state.container.offsetHeight);
        this.state.canvas.canvas.fitIntoStage(this.state.canvas.stage);
        this.state.canvas.highlightable.fitIntoStage(this.state.canvas.stage);
        this.state.canvas.zoomies.fitIntoStage(this.state.canvas.stage);
        this.state.canvas.confirmation.fitIntoStage(this.state.canvas.stage);
        this.state.canvas.drawHandler.fitIntoStage(this.state.canvas.stage);
        this.state.canvas.palette.fitIntoStage(this.state.canvas.stage);
        this.disableSmoothing(this.state.canvas);
    }

    private async redirectNoActive(): Promise<void> {
        const pending = await CanvasCache.getInstance().getPendingCanvas();

        if (pending === null) {
            this.redirectHome();
            return;
        }

        this.redirectPending(pending.canvasId);
    }

    private redirectHome(): void {
        this.setState({ ...this.state, redirectHome: true });
    }

    private redirectPending(canvasId: number): void {
        this.setState({
            ...this.state,
            redirectPending: true,
            canvasId: canvasId,
        });
    }

    private createCanvas(containerId: string, htmlCanvas: HTMLCanvasElement, properties: ViewProperties): DrawableCanvas {
        const stage = new Konva.Stage({
            container: containerId,
            width: window.innerWidth,
            height: window.innerHeight,
        });
        const canvasLayer = new Konva.Layer();
        const uiLayer = new Konva.Layer();
        const canvas = new Canvas(properties, htmlCanvas, canvasLayer, stage);
        const highlightable = new Highlightable(htmlCanvas.width, htmlCanvas.height, canvasLayer);
        const zoomies = new Zoomies(highlightable, canvasLayer, stage);
        const confirmation = new Confirmation(uiLayer);
        const drawHandler = new DrawHandler(canvas, confirmation, highlightable, canvasLayer);
        const palette = new Palette(canvas, drawHandler, highlightable, uiLayer);
        drawHandler.registerOnErrorListener((message) => this.setState({
            ...this.state,
            snackbar: <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={true}
                message={message}
                autoHideDuration={2000}
                onClose={(event, reason) => {
                    if (reason === "timeout") {
                        this.setState({ ...this.state, snackbar: null })
                    }
                }}
            />
        }));
        return { stage, canvasLayer, uiLayer, canvas, highlightable, zoomies, confirmation, drawHandler, palette };
    }

    private getFontWeight(selected: boolean): number {
        return selected ? 700 : 300;
    }

    private getIcon(selected: boolean, normalIcon: string, selectedIcon: string): string {
        return selected ? selectedIcon : normalIcon;
    }

    private toggleFreeDraw(): void {
        const toggled = !this.state.menu.freeDraw;
        this.updateMenuState({ freeDraw: toggled, autoZoom: false });
        this.updateFreeDraw(toggled, this.state.canvas);
        this.updateAutoZoom(false, this.state.canvas);
    }

    private updateFreeDraw(freeDraw: boolean, canvas: DrawableCanvas|null): void {
        if (freeDraw) {
            canvas?.drawHandler.enableFreeDraw();
        } else {
            canvas?.drawHandler.disableFreeDraw();
        }
    }

    private toggleAutoZoom(): void {
        const toggled = !this.state.menu.autoZoom;
        this.updateMenuState({ autoZoom: toggled, freeDraw: false });
        this.updateAutoZoom(toggled, this.state.canvas);
        this.updateFreeDraw(false, this.state.canvas);
    }

    private updateAutoZoom(autoZoom: boolean, canvas: DrawableCanvas|null): void {
        if (autoZoom) {
            canvas?.zoomies.enableAutoZoom();
        } else {
            canvas?.zoomies.disableAutoZoom();
        }
    }

    private toggleColourPalette(): void {
        const toggled = !this.state.menu.colourPalette;
        this.updateMenuState({ colourPalette: toggled });
        this.updateColourPalette(toggled, this.state.canvas);
    }

    private updateColourPalette(colourPalette: boolean, canvas: DrawableCanvas|null): void {
        if (colourPalette) {
            canvas?.palette.show();
        } else {
            canvas?.palette.hide();
        }
    }

    private toggleGridLines(): void {
        this.updateMenuState({ gridLines: !this.state.menu.gridLines });
    }

    private toggleUndo(): void {
        this.state.canvas?.drawHandler.undo();
    }

    private toggleGotoCoordinates(): void {
        this.updateMenuState({ gotoCoordinates: !this.state.menu.gotoCoordinates });
    }

    private updateGotoX(event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>): void {
        this.setState({ ...this.state, gotoCoords: { ...this.state.gotoCoords, x: Number(event.target?.value) } });
    }

    private updateGotoY(event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>): void {
        this.setState({ ...this.state, gotoCoords: { ...this.state.gotoCoords, y: Number(event.target?.value) } });
    }

    private updateMenuState(state: Record<string, boolean>): void {
        const menu = { ...this.state.menu, ...state };
        localStorage.setItem(Draw.MENU_KEY, JSON.stringify(menu));
        this.setState({ ...this.state, menu: menu });
    }

    private getMenuState(): MenuState {
        const menu = localStorage.getItem(Draw.MENU_KEY);

        if (!menu) {
            return this.state.menu;
        }

        const decoded = JSON.parse(menu);

        if (!decoded) {
            return this.state.menu;
        }

        return decoded;
    }

    private handleOkGoto(): void {
        if (this.state.gotoCoords.x !== null && this.state.gotoCoords.y !== null) {
            this.state.canvas?.zoomies.zoomToCoords({ x: this.state.gotoCoords.x, y: this.state.gotoCoords.y });
        }

        this.toggleGotoCoordinates();
    }

    private handleCancelGoto(): void {
        this.toggleGotoCoordinates();
    }

    private async loadCanvas(properties: CanvasProperties): Promise<void> {
        if (properties.state === CanvasState.PENDING) {
            this.redirectPending(properties.canvasId);
            return;
        }

        if (properties.state !== CanvasState.ACTIVE) {
            this.redirectHome();
            return;
        }

        const viewProps = {
            canvasId: properties.canvasId,
            width: properties.canvasProps.width,
            height: properties.canvasProps.height,
            colourPalette: properties.canvasProps.colourPalette,
        };

        // Subscribe before querying view so that we don't miss any pixel changes during flight time of query
        const viewChangedId = await ApiClient.getInstance().subscribeViewChanged((view) => {
            if (view.canvasId === properties.canvasId) {
                this.updateView(view.coords, view.colours, viewProps);
            }
        });

        // Redirecting when canvas completes
        const canvasCompletedId = await ApiClient.getInstance().subscribeCanvasCompleted((canvasId) => {
            if (canvasId === properties.canvasId) {
                this.redirectHome();
            }
        });

        // Create dom element for displaying canvas
        const htmlCanvas = document.createElement("canvas");
        htmlCanvas.width = properties.canvasProps.width;
        htmlCanvas.height = properties.canvasProps.height;

        // Write queried canvas to dom
        const view = await ApiClient.getInstance().queryCanvasView(properties.canvasId);

        if (view === null) {
            this.redirectHome();
            return;
        }

        this.initialiseCanvasElement(htmlCanvas, view, viewProps);

        // Generate canvas ui
        const canvas = this.createCanvas("draw-canvas-container", htmlCanvas, viewProps);
        const container: HTMLDivElement|null = document.querySelector("#draw-canvas-parent");
        assert(container !== null, "Failed to retrieve parent");
        await this.initialiseCanvasContainer(container, canvas);

        // Initialise canvas settings from menu
        const menu = this.getMenuState();
        this.updateAutoZoom(menu.autoZoom, canvas);
        this.updateColourPalette(menu.colourPalette, canvas);
        this.updateFreeDraw(menu.freeDraw, canvas);

        // Store canvas in state
        this.setState({
            ...this.state,
            container: container,
            canvas: canvas,
            loading: false,
            menu: menu,
            viewChangedId: viewChangedId,
            canvasCompletedId: canvasCompletedId,
        });
    }

    private async initialiseCanvasContainer(container: HTMLDivElement, canvas: DrawableCanvas): Promise<void> {
        // Fit stage into parent
        canvas.stage.width(container.offsetWidth);
        canvas.stage.height(container.offsetHeight);

        // Load views
        canvas.canvas.load();
        canvas.drawHandler.load();
        canvas.highlightable.load();
        canvas.zoomies.load();
        canvas.confirmation.load();
        canvas.palette.load();

        // Add layers to render
        canvas.stage.add(canvas.canvasLayer);
        canvas.stage.add(canvas.uiLayer);

        // Fit views into stage
        canvas.canvas.fitIntoStage(canvas.stage);
        canvas.highlightable.fitIntoStage(canvas.stage);
        canvas.zoomies.fitIntoStage(canvas.stage);
        canvas.confirmation.fitIntoStage(canvas.stage);
        canvas.drawHandler.fitIntoStage(canvas.stage);
        canvas.palette.fitIntoStage(canvas.stage);

        this.disableSmoothing(canvas);
    }

    private initialiseCanvasElement(canvas: HTMLCanvasElement, view: string, properties: ViewProperties): void {
        const ctx = canvas.getContext("2d", { willReadFrequently: true });
        assert(ctx !== null, "Failed to retrieve 2d context");
        const data = decodeViewRgba(view, properties);
        ctx.putImageData(new ImageData(data, properties.width, properties.height, { colorSpace: "srgb" }), 0, 0);
    }

    private updateView(coords: number[], colours: number[], properties: ViewProperties): void {
        if (this.state.canvas === null) {
            const pendingChanges = this.state.pendingChanges;
            pendingChanges.push({ coords, colours });
            this.setState({ ...this.state, pendingChanges });
            return;
        }

        const pendingChanges = this.state.pendingChanges;
        pendingChanges.push({ coords, colours });

        if (this.state.pendingChanges.length !== 0) {
            this.setState({ ...this.state, pendingChanges: [] });
        }

        const toChangeX: number[] = [];
        const toChangeY: number[] = [];
        const toChangeColour: string[] = [];

        for (const pending of pendingChanges) {
            const pixels = decodePixels(pending.coords, pending.colours, properties);
            toChangeX.push(...pixels.xCoords);
            toChangeY.push(...pixels.yCoords);
            toChangeColour.push(...pixels.colours);
        }

        this.state.canvas?.canvas.updateCanvas(toChangeX, toChangeY, toChangeColour);
    }

    private disableSmoothing(canvas: DrawableCanvas): void {
        const ctx: CanvasRenderingContext2D = canvas.canvasLayer.getContext()._context;
        ctx.imageSmoothingEnabled = false;
        // @ts-ignore backwards compat with older browsers
        ctx.mozImageSmoothingEnabled = false;
        // @ts-ignore backwards compat with older browsers
        ctx.webkitImageSmoothingEnabled = false;
        // @ts-ignore backwards compat with older browsers
        ctx.msImageSmoothingEnabled = false;
    }

    private getCanvasIdParam(): number|null {
        const searchParams: URLSearchParams = new URLSearchParams(window.location.search);

        if (!searchParams.has(CANVAS_ID_KEY)) {
            return null;
        }

        const canvasId = searchParams.get(CANVAS_ID_KEY);

        if (canvasId === null) {
            return null;
        }

        return Number(canvasId);
    }

    private async getActiveCanvasId(): Promise<number|null> {
        const active = await CanvasCache.getInstance().refresh().getActiveCanvas();

        if (active === null) {
            return null;
        }

        return active.canvasId;
    }
}
