import { Component, Fragment, ReactNode } from "react";
import { Link, Navigate } from "react-router-dom";
import { animateScroll, Link as SmoothScroll } from "react-scroll";
import logo from "../../assets/images/rgb-logo-blue.svg";
import { CanvasCache } from "../cache/canvas_cache";
import { WalletHandler, WalletState } from "../wallet/wallet_handler";
import { CANVAS_ID_KEY } from "../util/common";
import { ResolvablePromise } from "../util/helpers";
import { AlertProps } from "./alert";
import "./navbar.css";

interface NavBarProps {
    marginLeft?: string;
    redirect: boolean;
    floating?: boolean;
    alertCb?: (alert: AlertProps) => void;
}

interface NavBarState {
    walletState: WalletState;
    activeId: number|null;
    pendingId: number|null;
    subscribeStateChangedId: string|null;
    subscribeConnectOpenId: string|null;
    subscribeChainOpenId: string|null;
    authenticating: boolean;
    targetUrl: string|null;
    redirect: boolean;
    connectOpened: boolean;
    chainOpened: boolean;
}

export class NavBar extends Component<NavBarProps, NavBarState> {
    state: NavBarState = {
        walletState: WalletState.LOADING,
        activeId: null,
        pendingId: null,
        subscribeStateChangedId: null,
        subscribeConnectOpenId: null,
        subscribeChainOpenId: null,
        authenticating: false,
        targetUrl: null,
        redirect: false,
        connectOpened: false,
        chainOpened: false,
    };

    public async componentDidMount(): Promise<void> {
        const subscribeStateChangedId = WalletHandler.getInstance().subscribeStateChanged(async (walletState) => {
            this.setState((state) => ({ ...state, walletState: walletState }));

            if (this.state.authenticating) {
                switch (walletState) {
                    case WalletState.AUTHENTICATED: {
                        const state = await this.getLatestState();

                        if (state.targetUrl) {
                            this.setState((state) => ({
                                ...state,
                                authenticating: false,
                                redirect: true,
                            }));
                            break;
                        }

                        this.setState((state) => ({
                            ...state,
                            authenticating: false,
                            targetUrl: null,
                        }));
                        break;
                    }

                    case WalletState.UNAUTHENTICATED:
                        animateScroll.scrollToBottom();
                        this.notifyUnsubscribed();
                        this.setState((state) => ({
                            ...state,
                            authenticating: false,
                            targetUrl: null,
                        }));
                        break;

                    case WalletState.EMAIL_NOT_VERIFIED:
                        this.notifyEmailNotVerified();
                        this.setState((state) => ({
                            ...state,
                            authenticating: false,
                            targetUrl: null,
                        }));
                        break;

                    case WalletState.PENDING_AUTHENTICATION: {
                        const state = await this.getLatestState();

                        if (state.authenticating && state.targetUrl) {
                            await this.authenticate(state.targetUrl);
                        }

                        break;
                    }

                    case WalletState.NOT_CONNECTED:
                        await WalletHandler.getInstance().openConnect();
                        break;

                    case WalletState.UNSUPPORTED_NETWORK:
                        await WalletHandler.getInstance().openChain();
                        break;
                }
            }
        });

        const subscribeConnectOpenId = WalletHandler.getInstance().subscribeConnectOpen(async (open) => {
            if (!open) {
                const state = await this.getLatestState();

                if (state.connectOpened && state.walletState === WalletState.NOT_CONNECTED) {
                    this.setState((state) => ({ ...state, authenticating: false }));
                }

                this.setState((state) => ({ ...state, connectOpened: false }));
            }
        });

        const subscribeChainOpenId = WalletHandler.getInstance().subscribeChainOpen(async (open) => {
            if (!open) {
                const state = await this.getLatestState();

                if (state.chainOpened && state.walletState === WalletState.UNSUPPORTED_NETWORK) {
                    this.setState((state) => ({ ...state, authenticating: false }));
                }

                this.setState((state) => ({ ...state, chainOpened: false }));
            }
        });

        this.setState((state) => ({
            ...state,
            subscribeStateChangedId: subscribeStateChangedId,
            subscribeConnectOpenId: subscribeConnectOpenId,
            subscribeChainOpenId: subscribeChainOpenId,
        }));

        // Provides draw url for currently active canvas
        const active = await CanvasCache.getInstance().getActiveCanvas();

        if (active) {
            this.setState((state) => ({ ...state, activeId: active.canvasId }));
            return;
        }

        // Provides pending url for next canvas
        const pending = await CanvasCache.getInstance().getPendingCanvas();

        if (pending) {
            this.setState((state) => ({ ...state, pendingId: pending.canvasId }));
        }
    }

    public componentWillUnmount(): void {
        if (this.state.subscribeStateChangedId) {
            WalletHandler.getInstance().unsubscribeStateChanged(this.state.subscribeStateChangedId);
        }

        if (this.state.subscribeConnectOpenId) {
            WalletHandler.getInstance().unsubscribeConnectOpen(this.state.subscribeConnectOpenId);
        }

        if (this.state.subscribeChainOpenId) {
            WalletHandler.getInstance().unsubscribeChainOpen(this.state.subscribeChainOpenId);
        }
    }

    public render(): ReactNode {
        if (this.state.redirect && this.state.targetUrl) {
            return <Navigate to={this.state.targetUrl} />;
        }

        const drawUrl = (this.state.activeId !== null) ? `/draw?${CANVAS_ID_KEY}=${this.state.activeId}` :
            (this.state.pendingId !== null) ? `/pending?${CANVAS_ID_KEY}=${this.state.pendingId}` : "/draw";
        return (
            <div className={"container" + ((this.props.floating ?? true) ? "" : " nav-bar-block")}>
                <div id="nav-bar-row">
                    <Link id="nav-logo" to="/" style={{ marginLeft: this.props.marginLeft ?? "0" }}>
                        <img alt="" src={logo}/>
                    </Link>
                    <nav id="desktop-menu">
                        <ul>
                            <li><Link to="/"><p>Home</p></Link></li>
                            <li>{this.createLink(drawUrl, <p>Draw</p>)}</li>
                            <li>{this.createLink("/gallery", <p>Gallery</p>)}</li>
                            <li>{this.createLink("/beta", <button>BETA</button>)}</li>
                        </ul>
                    </nav>
                    <input type="checkbox" id="mobile-menu-input" />
                    <label id="mobile-menu" htmlFor="mobile-menu-input">
                        <nav id="sidebar-menu">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>{this.createLink(drawUrl, <Fragment>Draw</Fragment>)}</li>
                                <li>{this.createLink("/gallery", <Fragment>Gallery</Fragment>)}</li>
                                <li>{this.createLink("/beta", <Fragment>BETA</Fragment>)}</li>
                            </ul>
                        </nav>
                    </label>
                </div>
            </div>
        );
    }

    private hideMenu(): void {
        const menuCheckbox = document.querySelector("#mobile-menu-input") as HTMLInputElement;
        menuCheckbox.checked = false;
        document.body.onclick = null;
    }

    private createLink(url: string, children: ReactNode): ReactNode {
        switch (this.state.walletState) {
            case WalletState.AUTHENTICATED:
                return (
                    <Link id={"nav-bar-authenticated"} to={url}>{children}</Link>
                );

            case WalletState.PENDING_AUTHENTICATION:
                return (
                    <Link id={"nav-bar-pending-authentication"}
                          to={url}
                          onClick={async (event) => {
                              event.preventDefault();
                              await this.authenticate(url);
                          }}
                    >{children}</Link>
                );

            case WalletState.NOT_CONNECTED:
                return (
                    <Link id={"nav-bar-not-connected"}
                          to={url}
                          onClick={async (event) => {
                              event.preventDefault();
                              this.setState((state) => ({
                                  ...state,
                                  authenticating: true,
                                  connectOpened: true,
                                  targetUrl: url,
                              }));
                              await WalletHandler.getInstance().openConnect();
                          }}
                    >{children}</Link>
                );

            case WalletState.UNSUPPORTED_NETWORK:
                return (
                    <Link id={"nav-bar-unsupported-network"}
                          to={url}
                          onClick={async (event) => {
                              event.preventDefault();
                              this.setState((state) => ({
                                  ...state,
                                  authenticating: true,
                                  chainOpened: true,
                                  targetUrl: url,
                              }));
                              await WalletHandler.getInstance().openChain();
                          }}
                    >{children}</Link>
                );

            case WalletState.EMAIL_NOT_VERIFIED:
                return (
                    <Link id={"nav-bar-email-not-verified"}
                          to={url}
                          onClick={async (event) => {
                              event.preventDefault();
                              await this.authenticate(url)
                          }}
                    >{children}</Link>
                );

            case WalletState.LOADING:
            case WalletState.AUTHENTICATING:
                return (
                    <Link id={"nav-bar-loading"}
                          to={url}
                          onClick={(event) => {
                              event.preventDefault();
                              this.notifyLoading();
                          }}
                    >{children}</Link>
                );

            case WalletState.UNAUTHENTICATED:
            default:
                if (this.props.redirect) {
                    return (
                        <Link id={"nav-bar-unauthenticated"}
                              to={url}
                              onClick={(event) => {
                                  event.preventDefault();
                                  window.location.href = "/";
                              }}
                        >{children}</Link>
                    );
                }

                return (
                    <SmoothScroll to="subscribe"
                                  onClick={() => {
                                      this.hideMenu();
                                      this.notifyUnsubscribed();
                                  }}
                                  smooth={true}
                                  offset={-70}
                                  duration={500}>
                        {children}
                    </SmoothScroll>
                );
        }
    }

    private notifyLoading(): void {
        this.displayAlert("Wallet loading, please wait...", "message", 2000);
    }

    private notifyEmailNotVerified(): void {
        this.displayAlert("Email not verified, please check emails for verification link", "error");
    }

    private notifyUnsubscribed(): void {
        this.displayAlert("Enter your email to apply for beta access", "warning");
    }

    private displayAlert(
        message: string,
        severity: "success" | "info" | "warning" | "error" | "message",
        autoHideDuration?: number
    ): void {
        if (!!this.props.alertCb) {
            this.props.alertCb({
                message: message,
                severity: severity,
                autoHideDuration: autoHideDuration,
            });
        }
    }

    private async authenticate(url: string): Promise<void> {
        if (!await WalletHandler.getInstance().isAuthenticated()) {
            try {
                this.setState((state) => ({
                    ...state,
                    authenticating: true,
                    targetUrl: url,
                }));
                await WalletHandler.getInstance().authenticate();
            } catch (error) {
                if (error instanceof Error && error.toString().includes("UserRejectedRequestError")) {
                    this.displayAlert("Please sign in to access beta", "error");
                } else {
                    this.displayAlert("Unknown error occurred", "error", 2000);
                }

                this.setState((state) => ({
                    ...state,
                    authenticating: false,
                    targetUrl: null,
                }));
            }
        } else {
            this.setState((state) => ({
                ...state,
                authenticating: false,
                targetUrl: url,
                redirect: true,
            }));
        }
    }

    private getLatestState(): Promise<NavBarState> {
        const latestState = new ResolvablePromise<NavBarState>();
        // Hack to get latest queued state
        this.setState((state) => {
            latestState.resolve(state);
            return state;
        });
        return latestState.promise();
    }
}
