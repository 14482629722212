import { Component, ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AlertProps } from "../layout/alert";
import { WalletHandler, WalletState } from "../wallet/wallet_handler";
import { ResolvablePromise } from "../util/helpers";

interface AuthenticateProps {
    alertCb?: (alert: AlertProps) => void;
}

interface AuthenticateState {
    authenticated: boolean|null;
    subscribeStateChangedId: string|null;
    subscribeConnectOpenId: string|null;
    subscribeChainOpenId: string|null;
    showOverlay: boolean;
    connectOpened: boolean;
    chainOpened: boolean;
    walletState: WalletState;
}

export class Authenticate extends Component<AuthenticateProps, AuthenticateState> {
    state: AuthenticateState = {
        authenticated: null,
        subscribeStateChangedId: null,
        subscribeConnectOpenId: null,
        subscribeChainOpenId: null,
        showOverlay: false,
        connectOpened: false,
        chainOpened: false,
        walletState: WalletState.LOADING,
    };

    public async componentDidMount(): Promise<void> {
        const subscribeStateChangedId = WalletHandler.getInstance().subscribeStateChanged(async (walletState) => {
            this.setState((state) => ({ ...state, walletState: walletState }));

            switch (walletState) {
                case WalletState.AUTHENTICATED:
                    this.setState((state) => ({ ...state, authenticated: true, showOverlay: false }));
                    break;

                case WalletState.UNAUTHENTICATED:
                case WalletState.EMAIL_NOT_VERIFIED:
                    this.setState((state) => ({ ...state, authenticated: false, showOverlay: true }));
                    break;

                case WalletState.NOT_CONNECTED:
                    await WalletHandler.getInstance().openConnect();
                    this.setState((state) => ({ ...state, authenticated: null, showOverlay: false, connectOpened: true }));
                    break;

                case WalletState.UNSUPPORTED_NETWORK:
                    await WalletHandler.getInstance().openChain();
                    this.setState((state) => ({ ...state, authenticated: null, showOverlay: false, chainOpened: true }));
                    break;

                case WalletState.PENDING_AUTHENTICATION:
                    this.setState((state) => ({ ...state, authenticated: null, showOverlay: true }));

                    if (!await WalletHandler.getInstance().isAuthenticated()) {
                        try {
                            await WalletHandler.getInstance().authenticate();
                        } catch (error) {
                            if (error instanceof Error && error.toString().includes("UserRejectedRequestError")) {
                                this.displayAlert("Please sign in to access beta", "error");
                            } else {
                                this.displayAlert("Unknown error occurred", "error", 2000);
                            }

                            this.setState((state) => ({ ...state, authenticated: false, showOverlay: true }));
                        }
                    } else {
                        this.setState((state) => ({ ...state, authenticated: true, showOverlay: false }));
                    }
                    break;

                case WalletState.LOADING:
                case WalletState.AUTHENTICATING:
                default:
                    this.setState((state) => ({ ...state, authenticated: null, showOverlay: true }));
                    break;
            }
        });
        const subscribeConnectOpenId = WalletHandler.getInstance().subscribeConnectOpen(async (open) => {
            if (!open) {
                const state = await this.getLatestState();

                if (state.connectOpened && state.walletState === WalletState.NOT_CONNECTED) {
                    this.setState((state) => ({ ...state, authenticated: false, showOverlay: true }));
                }
            }
        });
        const subscribeChainOpenId = WalletHandler.getInstance().subscribeChainOpen(async (open) => {
            if (!open) {
                const state = await this.getLatestState();

                if (state.chainOpened && state.walletState === WalletState.UNSUPPORTED_NETWORK) {
                    this.setState((state) => ({ ...state, authenticated: false, showOverlay: true }));
                }
            }
        });
        this.setState((state) => ({
            ...state,
            subscribeStateChangedId: subscribeStateChangedId,
            subscribeConnectOpenId: subscribeConnectOpenId,
            subscribeChainOpenId: subscribeChainOpenId,
        }));
    }

    public componentWillUnmount(): void {
        if (this.state.subscribeStateChangedId) {
            WalletHandler.getInstance().unsubscribeStateChanged(this.state.subscribeStateChangedId);
        }

        if (this.state.subscribeConnectOpenId) {
            WalletHandler.getInstance().unsubscribeConnectOpen(this.state.subscribeConnectOpenId);
        }

        if (this.state.subscribeChainOpenId) {
            WalletHandler.getInstance().unsubscribeChainOpen(this.state.subscribeChainOpenId);
        }
    }

    public render(): ReactNode {
        // Redirect if not authenticated
        if (this.state.authenticated === false) {
            return <Navigate to="/"/>;
        }

        // TODO: loading overlay
        return <Outlet/>;
    }

    private displayAlert(
        message: string,
        severity: "success" | "info" | "warning" | "error" | "message",
        autoHideDuration?: number
    ): void {
        if (!!this.props.alertCb) {
            this.props.alertCb({
                message: message,
                severity: severity,
                autoHideDuration: autoHideDuration,
            });
        }
    }

    private getLatestState(): Promise<AuthenticateState> {
        const latestState = new ResolvablePromise<AuthenticateState>();
        // Hack to get latest queued state
        this.setState((state) => {
            latestState.resolve(state);
            return state;
        });
        return latestState.promise();
    }
}
