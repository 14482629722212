import { Component, ReactNode } from "react";
import win from "../../assets/images/win-image.svg";
import "./win.css";

export class Win extends Component {
    componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#win-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));
    }

    render(): ReactNode {
        return (
            <div id="win-row-background">
                <div className="container">
                    <section id="win-row">
                        <div id="win-description-col" className="hidden">
                            <h2 className="rgb-white">WIN</h2>
                            <p className="rgb-light-grey">Not only will you be an NFT creator but you will also have the opportunity to win <strong>BIG</strong>. Each NFT acts as a pool where tokens are distributed to the lucky winners!</p>
                        </div>
                        <div id="win-image-col" className="hidden">
                            <img src={win} alt=""/>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
