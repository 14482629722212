import { Component, ReactNode } from "react";
import CookieConsent from "react-cookie-consent";
import { theme } from "./theme";

export class Cookies extends Component {
    render(): ReactNode {
        return (
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="_cc"
                style={{ background: "#2B373B" }}
                contentStyle={{ marginLeft: "20px" }}
                buttonStyle={{ background: theme.palette.primary.main, color: "white", borderRadius: "3px", marginRight: "20px" }}
                expires={150}
            >
                We use cookies to enhance the user experience
            </CookieConsent>
        );
    }
}
