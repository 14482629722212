import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#7273F1",
        },
        secondary: {
            main: "#E55A9D",
        },
    },
    typography: {
        fontFamily: [
            "Metropolis",
            "sans-serif",
        ].join(","),
    },
});
