import { Component, ReactNode } from "react";
import logo from "../../assets/images/rgb-logo-white.svg"
import discord from "../../assets/images/discord-logo-white.svg"
import github from "../../assets/images/github-logo-white.svg"
import medium from "../../assets/images/medium-logo-white.svg"
import twitter from "../../assets/images/twitter-logo-white.svg"
import "./footer.css";

export class Footer extends Component {
    render(): ReactNode {
        return (
            <div id="footer-background">
                <div className="container" id="footer-container">
                    <section id="footer-row">
                        <div>
                            <a id="footer-logo" href="/">
                                <img alt="" src={logo}/>
                            </a>
                        </div>
                        <div id="copyright">
                            <p className="rgb-white">{"Copyright \u00A9 " + new Date().getFullYear() + " Tomilion. All Rights Reserved"}</p>
                        </div>
                        <div>
                            <a href="https://twitter.com/rgblisk">
                                <img alt="" src={twitter}/>
                            </a>
                            <a href="https://discord.gg/aBNf5GJ2">
                                <img alt="" src={discord}/>
                            </a>
                            <a href="https://rgblisk.medium.com">
                                <img alt="" src={medium}/>
                            </a>
                            <a href="https://github.com/tomilion/rgb-core">
                                <img alt="" src={github}/>
                            </a>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
