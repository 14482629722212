import AssessmentIcon from "@mui/icons-material/Assessment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CollectionsIcon from "@mui/icons-material/Collections";
import DrawIcon from "@mui/icons-material/Draw";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Component, Fragment, ReactNode } from "react";
import { Link } from "react-router-dom";
import RgbIcon from "../../assets/images/r-logo-grey.svg";
import { CanvasCache } from "../../components/cache/canvas_cache";
import { CANVAS_ID_KEY } from "../../components/util/common";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
        "& .MuiDrawer-paper": {
            position: "relative",
            whiteSpace: "nowrap",
            width: drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            ...(!open && {
                overflowX: "hidden",
                transition: theme.transitions.create("width", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up("sm")]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

interface NavBarState {
    open: boolean;
    activeId: number|null;
    pendingId: number|null;
}

export class NavBar extends Component<any, NavBarState> {
    state = {
        open: false,
        activeId: null,
        pendingId: null,
    };

    public async componentDidMount(): Promise<void> {
        const active = await CanvasCache.getInstance().getActiveCanvas();

        if (active) {
            this.setState((state) => ({ ...state, activeId: active.canvasId }));
            return;
        }

        const pending = await CanvasCache.getInstance().getPendingCanvas();

        if (pending) {
            this.setState((state) => ({ ...state, pendingId: pending.canvasId }));
            return;
        }
    }

    public render(): ReactNode {
        const drawUrl = (this.state.activeId !== null) ? `/draw?${CANVAS_ID_KEY}=${this.state.activeId}` :
            (this.state.pendingId !== null) ? `/pending?${CANVAS_ID_KEY}=${this.state.pendingId}` : `/draw`;
        return (
            <Fragment>
                <AppBar position="absolute" open={this.state.open}>
                    <Toolbar
                        sx={{
                            pr: "24px", // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => this.toggleNavBar()}
                            sx={{
                                marginRight: "36px",
                                ...(this.state.open && { display: "none" }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Dashboard
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={this.state.open}>
                    <Toolbar
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            px: [1],
                        }}
                    >
                        <IconButton onClick={() => this.toggleNavBar()}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <ListItemButton component={Link} to="/">
                            <ListItemIcon>
                                <img alt="" src={RgbIcon} width="24" height="22" />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItemButton>
                        <ListItemButton component={Link} to="/beta" sx={{ backgroundColor: (theme) =>
                                theme.palette.mode === "light"
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900] }}>
                            <ListItemIcon>
                                <AssessmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItemButton>
                        <ListItemButton component={Link} to={drawUrl}>
                            <ListItemIcon>
                                <DrawIcon />
                            </ListItemIcon>
                            <ListItemText primary="Draw" />
                        </ListItemButton>
                        <ListItemButton component={Link} to="/gallery">
                            <ListItemIcon>
                                <CollectionsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Gallery" />
                        </ListItemButton>
                    </List>
                </Drawer>
            </Fragment>
        );
    }

    private toggleNavBar(): void {
        this.setState({ open: !this.state.open });
    }
}
