import Konva from "konva";
import { DeferredRender } from "./deferred_render";
import { Coords } from "./types";

export class Highlightable implements DeferredRender {
    public static readonly HIGHLIGHT_SCALE: number = 7;

    private readonly width: number;
    private readonly height: number;
    private readonly layer: Konva.Layer;
    private readonly group: Konva.Group;
    private readonly border: Konva.Rect;
    private readonly background: Konva.Rect;
    private canSelect: boolean = true;
    private selectedCoords: Coords|null = null;
    private touchEvent: boolean = false;
    private onSelectListeners: ((coords: Coords) => void)[] = [];
    private onDeselectListeners: ((coords: Coords) => void)[] = [];
    private onHighlightListeners: ((coords: Coords) => void)[] = [];
    private onUnhighlightListeners: ((coords: Coords) => void)[] = [];
    private onClickListeners: ((coords: Coords) => void)[] = [];

    public constructor(width: number, height: number, layer: Konva.Layer) {
        this.width = width;
        this.height = height;
        this.layer = layer;
        this.group = new Konva.Group({
            visible: false
        });
        this.border = new Konva.Rect({
            x: -0.05,
            y: -0.05,
            width: 1.1,
            height: 1.1,
            stroke: "#ffc844",
            strokeWidth: 0.11,
            dash: [0.3, 0.5, 0.6, 0.5, 0.6, 0.5, 0.6, 0.5, 0.3, 0],
        });
        this.background = new Konva.Rect({
            width: 1,
            height: 1,
        });
    }

    public load(): void {
        this.group.add(this.background);
        this.group.add(this.border);
        this.layer.add(this.group);
        this.layer.on("touchstart", () => this.touchStart())
        this.layer.on("touchend", () => this.touchEnd())
        this.layer.on("dragstart", () => this.onDragStart());
        this.layer.on("click", () => this.onClick());
        this.layer.on("tap", () => this.onTap());
        this.layer.on("mousemove touchmove", () => this.onMouseMove());
        this.layer.on("mouseout mouseleave touchend", () => this.onMouseOut());
    }

    public unload(): void {
        this.group.remove();
        this.group.removeChildren();
        this.layer.off("touchstart touchend dragstart click tap mousemove touchmove mouseout mouseleave touchend");
    }

    public fitIntoStage(stage: Konva.Stage): void {
        // Do nothing
    }

    public registerOnSelectListener(callback: (coords: Coords) => void): void {
        this.onSelectListeners.push(callback);
    }

    public registerOnDeselectListener(callback: (coords: Coords) => void): void {
        this.onDeselectListeners.push(callback);
    }

    public registerOnHighlightListener(callback: (coords: Coords) => void): void {
        this.onHighlightListeners.push(callback);
    }

    public registerOnUnhighlightListener(callback: (coords: Coords) => void): void {
        this.onUnhighlightListeners.push(callback);
    }

    public registerOnClickListener(callback: (coords: Coords) => void): void {
        this.onClickListeners.push(callback);
    }

    public canHighlight(): boolean {
        return (this.layer.scaleX() > Highlightable.HIGHLIGHT_SCALE);
    }

    public getSelectedCoords(): Coords|null {
        return this.selectedCoords;
    }

    public getHighlightedCoords(): Coords|null {
        if (!this.group.isVisible()) {
            return null;
        }

        return { x: this.group.x(), y: this.group.y() };
    }

    public selectCoords(coords: Coords): void {
        this.highlight(coords);
        this.selectedCoords = coords;
        this.onSelectListeners.forEach((listener) => listener(coords));
    }

    public updateCanSelect(canSelect: boolean): void {
        this.canSelect = canSelect;
    }

    public updateColour(colour: string|null): void {
        if (this.background.fill() !== colour) {
            // @ts-ignore fill supports null (using null when no fill required)
            this.background.fill(colour);
        }
    }

    public refresh(): void {
        this.deselect();

        // Minimum zoom allowed for rendering highlight on canvas
        if (!this.canHighlight()) {
            this.unhighlight();
            return;
        }

        const coords = this.getPointerCoords();

        if (coords !== null) {
            this.highlight(coords);
        }
    }

    private touchStart(): void {
        this.touchEvent = true;
    }

    private touchEnd(): void {
        this.touchEvent = false;
    }

    private onClick(): void {
        // Second click on canvas cancels any pending pixel selection
        if (this.selectedCoords !== null) {
            this.refresh();
            return;
        }

        this.onSelect();
    }

    private onTap(): void {
        // Second tap on canvas cancels any pending pixel selection
        if (this.selectedCoords !== null) {
            this.deselect();
            this.unhighlight();
            return;
        }

        this.onSelect();
    }

    private onSelect(): void {
        const coords = this.getPointerCoords();

        if (coords === null) {
            return;
        }

        this.onClickListeners.forEach((listener) => listener(coords));

        if (!this.canSelect || !this.canHighlight()) {
            return;
        }

        this.selectCoords(coords);
    }

    private onMouseMove(): void {
        // Cannot highlight other pixel if currently selected
        if (this.selectedCoords !== null) {
            return;
        }

        // Minimum zoom allowed for rendering highlight on canvas
        if (!this.canHighlight()) {
            this.unhighlight();
            this.deselect();
            return;
        }

        const coords = this.getPointerCoords();

        if (coords === null) {
            this.unhighlight();
            return;
        }

        this.highlight(coords);
    }

    private onMouseOut(): void {
        if (this.selectedCoords === null) {
            this.unhighlight();
        }
    }

    private onDragStart(): void {
        this.deselect();

        if (this.touchEvent) {
            this.unhighlight();
        }
    }

    private deselect(): void {
        if (this.selectedCoords !== null) {
            const coords = this.selectedCoords;
            this.selectedCoords = null;
            this.onDeselectListeners.forEach((listener) => listener(coords));
        }
    }

    private highlight(coords: Coords): void {
        if (this.group.isVisible() && this.group.x() === coords.x && this.group.y() === coords.y) {
            return;
        }

        this.group.show();
        this.group.x(coords.x);
        this.group.y(coords.y);
        this.onHighlightListeners.forEach((listener) => listener(coords));
    }

    private unhighlight(): void {
        if (this.group.isVisible()) {
            this.group.hide();
            this.onUnhighlightListeners.forEach((listener) => listener({ x: this.group.x(), y: this.group.y() }));
        }
    }

    private getPointerCoords(): Coords|null {
        const pointer = this.layer.getRelativePointerPosition();

        if (pointer === null) {
            return null;
        }

        const x = Math.floor(pointer.x);
        const y = Math.floor(pointer.y);

        // Checking coords are within canvas
        if (x < 0 || x >= this.width || y < 0 || y >= this.height) {
            return null;
        }

        return { x, y };
    }
}
