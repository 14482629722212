import assert from "assert";
import Konva from "konva";
import { Component, ChangeEvent, Fragment, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { Canvas } from "../../components/canvas/canvas";
import { Highlightable } from "../../components/canvas/highlightable";
import { MediaControls } from "../../components/canvas/media_controls";
import { Zoomies } from "../../components/canvas/zoomies";
import { ApiClient } from "../../components/clients/api_client";
import { TimelapseSummary } from "../../components/clients/types";
import { CANVAS_ID_KEY } from "../../components/util/common";
import { TimelapseCanvas, TimelapseState } from "./types";
import autoZoomIcon from "../../assets/images/automatic-zoom.svg";
import autoZoomSelectedIcon from "../../assets/images/automatic-zoom-selected.svg";
import gotoCoordinatesIcon from "../../assets/images/goto.svg";
import gotoCoordinatesSelectedIcon from "../../assets/images/goto-selected.svg";
import gridLinesIcon from "../../assets/images/grid-lines.svg";
import gridLinesSelectedIcon from "../../assets/images/grid-lines-selected.svg";
import logo from "../../assets/images/r-logo-white.svg";
import "./timelapse.css";

let timelapse: Timelapse|null = null;

window.addEventListener("resize", () => timelapse?.onResize());

export class Timelapse extends Component<{}, TimelapseState> {
    state: TimelapseState = {
        redirect: false,
        menu: {
            autoZoom: true,
            gridLines: false,
            gotoCoordinates: false,
        },
        container: null,
        canvas: null,
        gotoCoords: { x: null, y: null },
    };

    async componentDidMount(): Promise<void> {
        timelapse = this;

        const canvasId = this.getCanvasId();

        if (canvasId === null) {
            this.setState({ ...this.state, redirect: true });
            return;
        }

        const summary = await ApiClient.getInstance().queryTimelapse(canvasId);

        if (summary === null) {
            this.setState({ ...this.state, redirect: true });
            return;
        }

        // Create dom element for displaying canvas
        const htmlCanvas = document.createElement("canvas");
        htmlCanvas.width = summary.width;
        htmlCanvas.height = summary.height;

        // Initialise canvas as white background
        const ctx = htmlCanvas.getContext("2d", { willReadFrequently: true });
        assert(ctx !== null, "Failed to retrieve 2d context");
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(0, 0, summary.width, summary.height);

        // Generate canvas ui
        const canvas = this.createCanvas("timelapse-canvas-container", htmlCanvas, summary);
        const container: HTMLDivElement|null = document.querySelector("#timelapse-canvas-parent");
        assert(container !== null, "Failed to retrieve parent");
        await this.loadCanvas(container, canvas);

        // Initialise canvas settings from menu
        this.updateAutoZoom(this.state.menu.autoZoom, canvas);

        // Store canvas in state
        this.updateCanvas(container, canvas);
    }

    componentWillUnmount(): void {
        if (this.state.canvas === null) {
            return;
        }

        this.state.canvas.canvas.unload();
        this.state.canvas.mediaControls.unload();
        this.state.canvas.highlightable.unload();
        this.state.canvas.zoomies.unload();
    }

    render(): ReactNode {
        return (
            <Fragment>
                { this.state.redirect ? (<Navigate to="/"/>) : null }
                <div id="timelapse-menu-container">
                    <input id="timelapse-hamburger-input" type="checkbox"/>
                    <label htmlFor="timelapse-hamburger-input" id="timelapse-hamburger-label">
                        <i></i>
                    </label>
                    <nav id="timelapse-menu">
                        <ul>
                            <Divider sx={{ my: 1 }} style={{ background: "#F2F2FC" }} />
                            <li>
                                <a href="/">
                                    <img src={logo} alt="Home" className="logo"/>
                                    Home
                                </a>
                            </li>
                            <li>
                                <a href="/beta">
                                    <AssessmentOutlinedIcon style={{ color: "#ffffff" }} />
                                    Dashboard
                                </a>
                            </li>
                            <li>
                                <a href="/gallery">
                                    <CollectionsOutlinedIcon style={{ color: "#ffffff" }} />
                                    Gallery
                                </a>
                            </li>
                            <Divider sx={{ my: 1 }} style={{ background: "#F2F2FC" }} />
                            <li onClick={() => this.toggleAutoZoom()}>
                                <button style={{fontWeight: this.getFontWeight(this.state.menu.autoZoom)}}>
                                    <img src={this.getIcon(this.state.menu.autoZoom, autoZoomIcon, autoZoomSelectedIcon)} alt="Auto Zoom" className="icon"/>
                                    Auto Zoom
                                </button>
                            </li>
                            <li onClick={() => this.toggleGridLines()}>
                                <button style={{fontWeight: this.getFontWeight(this.state.menu.gridLines)}}>
                                    <img src={this.getIcon(this.state.menu.gridLines, gridLinesIcon, gridLinesSelectedIcon)} alt="Grid Lines" className="icon"/>
                                    Grid Lines
                                </button>
                            </li>
                            <li onClick={() => this.toggleGotoCoordinates()}>
                                <button style={{fontWeight: this.getFontWeight(this.state.menu.gotoCoordinates)}}>
                                    <img src={this.getIcon(this.state.menu.gotoCoordinates, gotoCoordinatesIcon, gotoCoordinatesSelectedIcon)} alt="Goto Coordinates" className="icon"/>
                                    Goto Coordinates
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div id="timelapse-canvas-parent" onContextMenu={(e) => e.preventDefault()}>
                    <div id="timelapse-canvas-container"></div>
                </div>
                <Dialog open={this.state.menu.gotoCoordinates} onClose={() => this.handleCancelGoto()}>
                    <DialogContent>
                        <DialogContentText>
                            Enter coordinates
                        </DialogContentText>
                        <TextField
                            autoFocus
                            id="xCoord"
                            label="x"
                            type="number"
                            variant="standard"
                            onChange={(e) => this.updateGotoX(e)}
                        />
                        <TextField
                            id="yCoord"
                            label="y"
                            type="number"
                            variant="standard"
                            onChange={(e) => this.updateGotoY(e)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleOkGoto()}>Ok</Button>
                        <Button onClick={() => this.handleCancelGoto()}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }

    public onResize(): void {
        if (this.state.container === null || this.state.canvas === null) {
            return;
        }

        this.state.canvas.stage.width(this.state.container.offsetWidth);
        this.state.canvas.stage.height(this.state.container.offsetHeight);
        this.state.canvas.canvas.fitIntoStage(this.state.canvas.stage);
        this.state.canvas.mediaControls.fitIntoStage(this.state.canvas.stage);
        this.state.canvas.highlightable.fitIntoStage(this.state.canvas.stage);
        this.state.canvas.zoomies.fitIntoStage(this.state.canvas.stage);
        this.disableSmoothing(this.state.canvas);
    }

    private createCanvas(containerId: string, htmlCanvas: HTMLCanvasElement, summary: TimelapseSummary): TimelapseCanvas {
        const stage = new Konva.Stage({
            container: containerId,
            width: htmlCanvas.width,
            height: htmlCanvas.height,
        });
        const canvasLayer = new Konva.Layer();
        const uiLayer = new Konva.Layer();
        const canvas = new Canvas(summary, htmlCanvas, canvasLayer, stage);
        const mediaControls = new MediaControls(summary, canvas, uiLayer, stage);
        const highlightable = new Highlightable(htmlCanvas.width, htmlCanvas.height, canvasLayer);
        const zoomies = new Zoomies(highlightable, canvasLayer, stage);
        return { stage, canvasLayer, uiLayer, canvas, mediaControls, highlightable, zoomies };
    }

    private getFontWeight(selected: boolean): number {
        return selected ? 700 : 300;
    }

    private getIcon(selected: boolean, normalIcon: string, selectedIcon: string): string {
        return selected ? selectedIcon : normalIcon;
    }

    private toggleAutoZoom(): void {
        const toggled = !this.state.menu.autoZoom;
        this.updateMenuState({ autoZoom: toggled, freeDraw: false });
        this.updateAutoZoom(toggled, this.state.canvas);
    }

    private updateAutoZoom(autoZoom: boolean, canvas: TimelapseCanvas|null): void {
        if (autoZoom) {
            canvas?.zoomies.enableAutoZoom();
        } else {
            canvas?.zoomies.disableAutoZoom();
        }
    }

    private toggleGridLines(): void {
        this.updateMenuState({ gridLines: !this.state.menu.gridLines });
    }

    private toggleGotoCoordinates(): void {
        this.updateMenuState({ gotoCoordinates: !this.state.menu.gotoCoordinates });
    }

    private updateCanvas(container: HTMLDivElement, canvas: TimelapseCanvas): void {
        this.setState({ ...this.state, container: container, canvas: canvas });
    }

    private updateGotoX(event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>): void {
        this.setState({ ...this.state, gotoCoords: { ...this.state.gotoCoords, x: Number(event.target?.value) } });
    }

    private updateGotoY(event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>): void {
        this.setState({ ...this.state, gotoCoords: { ...this.state.gotoCoords, y: Number(event.target?.value) } });
    }

    private updateMenuState(state: Record<string, boolean>): void {
        this.setState({ ...this.state, menu: { ...this.state.menu, ...state } });
    }

    private handleOkGoto(): void {
        if (this.state.gotoCoords.x !== null && this.state.gotoCoords.y !== null)
        {
            this.state.canvas?.zoomies.zoomToCoords({ x: this.state.gotoCoords.x, y: this.state.gotoCoords.y });
        }

        this.toggleGotoCoordinates();
    }

    private handleCancelGoto(): void {
        this.toggleGotoCoordinates();
    }

    private async loadCanvas(container: HTMLDivElement, canvas: TimelapseCanvas): Promise<void> {
        // Fit stage into parent
        canvas.stage.width(container.offsetWidth);
        canvas.stage.height(container.offsetHeight);

        // Load views
        canvas.canvas.load();
        await canvas.mediaControls.load();
        canvas.highlightable.load();
        canvas.zoomies.load();

        // Add layers to render
        canvas.stage.add(canvas.canvasLayer);
        canvas.stage.add(canvas.uiLayer);

        // Fit views into stage
        canvas.canvas.fitIntoStage(canvas.stage);
        canvas.mediaControls.fitIntoStage(canvas.stage);
        canvas.highlightable.fitIntoStage(canvas.stage);
        canvas.zoomies.fitIntoStage(canvas.stage);

        this.disableSmoothing(canvas);
    }

    private disableSmoothing(canvas: TimelapseCanvas): void {
        const ctx: CanvasRenderingContext2D = canvas.canvasLayer.getContext()._context;
        ctx.imageSmoothingEnabled = false;
        // @ts-ignore backwards compat with older browsers
        ctx.mozImageSmoothingEnabled = false;
        // @ts-ignore backwards compat with older browsers
        ctx.webkitImageSmoothingEnabled = false;
        // @ts-ignore backwards compat with older browsers
        ctx.msImageSmoothingEnabled = false;
    }

    private getCanvasId(): number|null {
        const searchParams: URLSearchParams = new URLSearchParams(window.location.search);

        if (!searchParams.has(CANVAS_ID_KEY)) {
            return null;
        }

        const canvasId = searchParams.get(CANVAS_ID_KEY);

        if (!canvasId) {
            return null;
        }

        return Number(canvasId);
    }
}
