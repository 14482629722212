import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Mutex } from "async-mutex";
import { Component, Fragment, ReactNode } from "react";
import { formatEther } from "viem";
import { ApiClient } from "../../components/clients/api_client";
import { Transaction } from "../../components/clients/types";
import { LastTableRow } from "./last_table_row";
import { Title } from "./title";

interface Transactions {
    transactions: Transaction[];
    newTransactionId: string|null;
}

export class LiveTransactions extends Component<any, Transactions> {
    state: Transactions = {
        transactions: [],
        newTransactionId: null,
    };
    private lock: Mutex = new Mutex();

    public async componentDidMount(): Promise<void> {
        await this.lock.runExclusive(async () => {
            const transactions = await ApiClient.getInstance().queryTransactions();
            const newTransactionId = await ApiClient.getInstance().subscribeTransactionNew((transaction) => {
                this.setState({
                    transactions: [
                        this.formatTransaction(transaction),
                        ...this.state.transactions.splice(0, 9),
                    ],
                });
            });
            this.setState({
                ...this.state,
                newTransactionId: newTransactionId,
                transactions: this.formatTransactions(transactions ?? []),
            });
        });
    }

    public async componentWillUnmount(): Promise<void> {
        await this.lock.runExclusive(async () => {
            if (this.state.newTransactionId) {
                await ApiClient.getInstance().unsubscribeTransactionNew(this.state.newTransactionId);
            }
        });
    }

    public render(): ReactNode {
        return (
            <Fragment>
                <Title>Live Transaction Feed</Title>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Block:Index</TableCell>
                            <TableCell>Sender</TableCell>
                            <TableCell>Function</TableCell>
                            <TableCell>Gas</TableCell>
                            <TableCell>Fee</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.rows()}
                    </TableBody>
                </Table>
            </Fragment>
        );
    }

    private rows(): ReactNode {
        if (this.state.transactions.length === 0) {
            return (
                <LastTableRow key="0">
                    <TableCell>N/A</TableCell>
                    <TableCell>N/A</TableCell>
                    <TableCell>N/A</TableCell>
                </LastTableRow>
            );
        }

        let index = 0;

        return (
            <Fragment>
                {this.state.transactions.map((row) => {
                    const content = (
                        <Fragment>
                            <TableCell>{row.blockNumber}:{row.transactionIndex}</TableCell>
                            <TableCell>{row.senderAddress}</TableCell>
                            <TableCell>{row.functionName}</TableCell>
                            <TableCell>{row.gas}</TableCell>
                            <TableCell>{row.fee}</TableCell>
                        </Fragment>
                    );

                    if (index === this.state.transactions.length - 1) {
                        return (
                            <LastTableRow key={index++}>
                                {content}
                            </LastTableRow>
                        );
                    }

                    return (
                        <TableRow key={index++}>
                            {content}
                        </TableRow>
                    );
                })}
            </Fragment>
        );
    }

    private formatTransactions(transactions: Transaction[]): Transaction[] {
        return transactions.map((transaction) => this.formatTransaction(transaction));
    }

    private formatTransaction(transaction: Transaction): Transaction {
        return {
            ...transaction,
            gas: formatEther(BigInt(transaction.gas)),
            fee: formatEther(BigInt(transaction.fee)),
        };
    }
}
