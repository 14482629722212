export interface CanvasId {
    canvasId: number;
}

export interface CanvasIds {
    canvasIds: number[];
}

export interface CanvasPayload {
    ownerId: string;
    costPerPixel: number;
    startBlockHeight: number;
    endBlockHeight: number;
    width: number;
    height: number;
    timeBetweenDraws: number;
    colourPalette: string;
    maxPixelsPerTransaction: number;
    state: number;
}

export interface ViewChangedPayload {
    canvasId: number;
    coords: string;
    colours: string;
}

export enum CanvasState {
    PENDING = 0,
    ACTIVE = 1,
    COMPLETE = 2,
}
