import { Component, ReactNode } from "react";
import create from "../../assets/images/create-image.svg";
import "./create.css";

export class Create extends Component {
    componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#create-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));
    }

    render(): ReactNode {
        return (
            <div className="container" id="learn">
                <section id="create-row">
                    <div id="create-description-col" className="hidden">
                        <h2 className="rgb-green">Create</h2>
                        <p>Draw a masterpiece as we record and capture every moment. Each contribution becomes a part of history as the canvas is immortalised as an NFT. The final canvas is not only a snapshot but also a timelapse which will be sold to the highest bidder.</p>
                    </div>
                    <div id="create-image-col" className="hidden">
                        <img src={create} alt=""/>
                    </div>
                </section>
            </div>
        );
    }
}
